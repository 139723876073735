import { FunctionComponent, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../../../components/select';
import moment from 'moment';
import Button, { ButtonVariant } from '../../../../components/button';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import useAgencyStore from '../../../../store/agency';
import Loader from '../../../../components/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import exportToExcel from '../../../../functions/downloadXlsx';
import Input, { InputComponentType } from '../../../../components/input';
import { formatTwoDecimals } from '../../../../functions/format';
import Tooltip from '../../../../components/tooltipMain';
import Success from '../../transferOrders/successModal';
import Modal from '../../../../components/modal';
import NoItemsPhoto from '../../../../assets/empty-book.png';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';

const PaymentList: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { agency } = useAgencyStore();
    const navigate = useNavigate();
    const paramsYear = useParams().year;
    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);
    const [taxData, setTaxData] = useState<ObjectKeys>([]);

    const [pendingSum, setPendingSum] = useState('');
    const [paidSum, setPaidSum] = useState('');

    const [warningModal, setWarningModal] = useState(false);
    const [selectedYear, setSelectedYear] = useState<string>(paramsYear ? paramsYear : moment().year().toString());

    const [params, setParams] = useState({
        year: paramsYear ? paramsYear : moment().year().toString(),
        status: '',
    });

    const [loaderVisible, setLoaderVisible] = useState(false);

    async function getTaxes(): Promise<void> {
        setLoaderVisible(true);
        try {
            await communication
                .getTaxCalendar(params)
                .then((res: ObjectKeys) => {
                    const sortedTaxData = res.data.data.sort((a: ObjectKeys, b: ObjectKeys) => {
                        return a.month - b.month;
                    });
                    setTaxData(sortedTaxData);
                    setLoaderVisible(false);
                })
                .catch((error: any) => {
                    if (error) {
                        setLoaderVisible(false);
                        toast.error(error.response.data.message);
                    }
                });
        } catch (syncError: any) {
            setLoaderVisible(false);
        }
    }

    const getSumms = (param: ObjectKeys): void => {
        setLoaderVisible(true);
        try {
            communication
                .getCalendarSum(param.year)
                .then((res: ObjectKeys) => {
                    if (!res.data.pending_sum && !res.data.paid_sum) {
                        setPendingSum('0');
                        setPaidSum('0');
                    } else {
                        setPendingSum(res.data.pending_sum.toString());
                        setPaidSum(res.data.paid_sum.toString());
                    }
                })
                .catch((error: any) => {
                    if (error) {
                        setLoaderVisible(false);
                        toast.error(error.response.data.message);
                    }
                });
        } catch (syncError: any) {
            setLoaderVisible(false);
        }
    };

    const removePayment = (item: ObjectKeys): void => {
        setLoaderVisible(true);
        const payload = {
            month: item.month,
            year: item.year,
            status: 'pending',
            paid_date: null,
        };
        try {
            communication
                .updateTaxCalendar(payload, item.id)
                .then(() => {
                    toast.success(
                        `${t('pages.transfers.payment_lists.success').text} ${item.year}. ${
                            t('pages.transfers.payment_lists._year').text
                        }`,
                    );
                    getSumms(params);
                })
                .catch((err: any) => {
                    toast.error(err.response.data.message);
                })
                .then(() => {
                    getTaxes();
                });
        } catch (error: any) {
            console.error('An error occurred:', error);
        }
    };

    const updateTaxCalendar = (date: Date, item: ObjectKeys): void => {
        setLoaderVisible(true);
        const payload = {
            month: item.month,
            year: item.year,
            status: 'paid',
            paid_date: moment(date).format('YYYY-MM-DD'),
        };
        try {
            communication
                .updateTaxCalendar(payload, item.id)
                .then(() => {
                    toast.success(
                        `${t('pages.transfers.payment_lists.success').text} ${item.year}. ${
                            t('pages.transfers.payment_lists._year').text
                        }`,
                    );
                    getSumms(params);
                })
                .catch((err: any) => {
                    toast.error(err.response.data.message);
                })
                .then(() => {
                    handleDateChange(date, item.month);
                    getTaxes();
                });
        } catch (error: any) {
            console.error('An error occurred:', error);
        }
    };

    useEffect(() => {
        generateYearsForFilters();
    }, []);

    useEffect(() => {
        getTaxes();
        getSumms(params);
    }, [params]);

    useEffect(() => {
        if (paramsYear) {
            setSelectedYear(paramsYear);
        }
    }, [paramsYear]);

    const generateYearsForFilters = (): void => {
        setLoaderVisible(true);
        setYearsOptions([]);
        const currentYear = moment().year();
        const createYear = moment(agency.date_of_registration).year();
        for (let i = currentYear; i >= createYear; i--) {
            setYearsOptions((prevState) => [...prevState, { label: i, value: i }]);
        }
        setLoaderVisible(false);
    };
    const formatDate = (inputDate: string): string => {
        const date = new Date(inputDate);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are zero-based
        const year = date.getFullYear();
        return `${day}.${month}.${year}.`;
    };
    const getMonthName = (monthNumber: number): string => {
        const months = [
            t('pages.transfers.payment_lists.january').text,
            t('pages.transfers.payment_lists.february').text,
            t('pages.transfers.payment_lists.march').text,
            t('pages.transfers.payment_lists.april').text,
            t('pages.transfers.payment_lists.may').text,
            t('pages.transfers.payment_lists.june').text,
            t('pages.transfers.payment_lists.july').text,
            t('pages.transfers.payment_lists.august').text,
            t('pages.transfers.payment_lists.september').text,
            t('pages.transfers.payment_lists.october').text,
            t('pages.transfers.payment_lists.november').text,
            t('pages.transfers.payment_lists.december').text,
        ];
        if (monthNumber >= 1 && monthNumber <= 12) {
            return months[monthNumber - 1];
        } else {
            return 'Invalid month number';
        }
    };

    const handleDateChange = (value: Date, month: number): void => {
        const currentMonthNumber = month;
        const paymentMonth = moment(value).month() + 1;
        const paymentDay = moment(value).date();
        const paymentYear = moment(value).year();
        const day15th = getDayOf15th(parseInt(selectedYear), currentMonthNumber);

        if (paymentYear > parseInt(selectedYear)) {
            if (paymentYear - parseInt(selectedYear) > 1) {
                setWarningModal(true);
            } else if (paymentYear - parseInt(selectedYear) === 1) {
                if (paymentMonth === 1) {
                    if (paymentDay > 15) {
                        if (day15th === 'Saturday') {
                            if (paymentDay > 17) {
                                setWarningModal(true);
                            }
                        } else if (day15th === 'Sunday') {
                            if (paymentDay > 16) {
                                setWarningModal(true);
                            }
                        } else {
                            setWarningModal(true);
                        }
                    }
                } else {
                    setWarningModal(true);
                }
            }
        } else if (paymentYear === parseInt(selectedYear)) {
            if (paymentMonth - currentMonthNumber > 1) {
                setWarningModal(true);
            } else if (paymentMonth - currentMonthNumber === 1) {
                if (paymentDay > 15) {
                    if (day15th === 'Saturday') {
                        if (paymentDay > 17) {
                            setWarningModal(true);
                        }
                    } else if (day15th === 'Sunday') {
                        if (paymentDay > 16) {
                            setWarningModal(true);
                        }
                    } else {
                        setWarningModal(true);
                    }
                }
            }
        }
    };

    const getDayOf15th = (year: number, month: number): string => {
        if (month < 0 || month > 12) {
            throw new Error('Invalid month');
        }
        const fifteenthDate = new Date(year, month, 15);
        const dayOfWeekNumber = fifteenthDate.getDay();
        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayOfWeek = dayNames[dayOfWeekNumber];
        return dayOfWeek;
    };

    return (
        <>
            {loaderVisible && <Loader />}
            <ToastContainer />
            {warningModal && (
                <Modal modalVisible={true} closeModal={() => setWarningModal(false)}>
                    <Success close={() => setWarningModal(false)} />
                </Modal>
            )}
            <Container>
                <Filters>
                    <div className="filter-single">
                        <label className="second-title">{t('pages.transfers.payment_lists.year').text}</label>
                        <SelectComponent
                            optionList={yearsOptions}
                            defaultSelectedOption={yearsOptions.find(
                                (option) =>
                                    option.value === parseInt(paramsYear ? paramsYear : moment().year().toString()),
                            )}
                            className={'select-year'}
                            handleSelectedValue={(value: string) => {
                                setParams((prevState) => ({ ...prevState, year: value }));
                                navigate(`/transfer-orders/payment-list/${value}`);
                                setSelectedYear(value);
                            }}
                        />
                    </div>
                    {pendingSum && paidSum ? (
                        <div className="amount-rest">
                            <p className="third-title">
                                {t('pages.transfers.payment_lists.paid_sum').text} (RSD): {formatTwoDecimals(paidSum)}
                            </p>
                            <p className="third-title">
                                {t('pages.transfers.payment_lists.unpaid_sum').text} (RSD):{' '}
                                {formatTwoDecimals(pendingSum)}
                            </p>
                        </div>
                    ) : null}
                </Filters>
                <TableContainer>
                    {taxData.length > 0 ? (
                        <Table>
                            <thead>
                                <tr>
                                    <th>{t('pages.transfers.payment_lists.month').text}</th>
                                    <th>{t('pages.transfers.title').text} (RSD)</th>
                                    <th>{t('pages.transfers.payment_lists.paid').text}</th>
                                    <th>{t('pages.transfers.payment_lists.date').text}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from({ length: 12 }, (_, index) => {
                                    const monthNumber = index + 1;
                                    const monthName = getMonthName(monthNumber);
                                    const currentItem = taxData.find((item: ObjectKeys) => item.month === monthNumber);

                                    return (
                                        <tr key={index}>
                                            <td className="gray-style">{monthName}</td>
                                            <td className="gray-style">
                                                {currentItem?.sum ? (
                                                    formatTwoDecimals(currentItem.sum.toString())
                                                ) : (
                                                    <p
                                                        className="link"
                                                        onClick={() => {
                                                            navigate(
                                                                `/transfer-orders/${params.year}/${
                                                                    monthNumber > 9 ? monthNumber : `0${monthNumber}`
                                                                }`,
                                                            );
                                                        }}
                                                    >
                                                        {t('pages.transfers.payment_lists.no_orders').text}
                                                    </p>
                                                )}
                                            </td>
                                            <td>
                                                {taxData.find((item: ObjectKeys) => item.month === monthNumber)
                                                    ?.status === 'paid' ? (
                                                    <div className="icon-container-paid">
                                                        <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                                                    </div>
                                                ) : taxData.find((item: ObjectKeys) => item.month === monthNumber)
                                                      ?.status === 'pending' ? (
                                                    <div className="icon-container-unpaid">
                                                        <FontAwesomeIcon
                                                            icon={faExclamationCircle}
                                                            className="check-icon"
                                                        />
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td>
                                                {taxData.find((item: ObjectKeys) => item.month === monthNumber)
                                                    ?.status === 'paid' ? (
                                                    <div className="date-div">
                                                        {formatDate(
                                                            taxData.find(
                                                                (item: ObjectKeys) => item.month === monthNumber,
                                                            )?.paid_date,
                                                        ) || ''}
                                                        <div
                                                            onClick={() => {
                                                                removePayment(currentItem);
                                                            }}
                                                            className="checkbox"
                                                        >
                                                            <div className="checkbox">
                                                                <Tooltip
                                                                    text={t('pages.transfers.remove_payment_date').text}
                                                                    position="top"
                                                                    label={
                                                                        <FontAwesomeIcon
                                                                            icon={faTimes}
                                                                            className="checked"
                                                                            color="var(--danger)"
                                                                        />
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : taxData.find((item: ObjectKeys) => item.month === monthNumber)
                                                      ?.status === 'pending' ? (
                                                    <>
                                                        <DateWrapper>
                                                            {/* <span className="text">
                                                                {t('pages.transfers.choosePaymentDate').text}
                                                            </span> */}
                                                            <Input
                                                                type={InputComponentType.Date}
                                                                date={null}
                                                                onChange={(value: Date) => {
                                                                    updateTaxCalendar(value, currentItem);
                                                                }}
                                                                inputClassName="payment-tax-date"
                                                                placeholder={t('pages.transfers.enter_date').text}
                                                            />
                                                        </DateWrapper>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    ) : (
                        <NoItems>
                            <div>
                                <img src={NoItemsPhoto} alt="photo" />
                                <div className="description">
                                    <h4>{t('pages.transfers.payment_lists.noData_1').text}</h4>
                                    <h4
                                        onClick={() => {
                                            navigate(`/transfer-orders/${paramsYear}`);
                                        }}
                                        className="link"
                                    >
                                        {t('pages.transfers.payment_lists.noData_link').text}
                                    </h4>
                                </div>
                            </div>
                        </NoItems>
                    )}
                </TableContainer>
                <Table style={{ display: 'none' }} id="download">
                    {taxData.length > 0 ? (
                        <tbody>
                            <tr>
                                <th>{t('pages.transfers.payment_lists.paid_sum').text} (RSD)</th>
                                <th>{paidSum ? formatTwoDecimals(paidSum) : ''}</th>
                            </tr>
                            <tr>
                                <th>{t('pages.transfers.payment_lists.unpaid_sum').text} (RSD)</th>
                                <th>{pendingSum ? formatTwoDecimals(pendingSum) : ''}</th>
                            </tr>
                            <tr>
                                <th>{t('pages.transfers.payment_lists.month').text}</th>
                                <th>{t('pages.transfers.title').text} (RSD)</th>
                                <th>{t('pages.transfers.payment_lists.paid').text}</th>
                                <th>{t('pages.transfers.payment_lists.date').text}</th>
                            </tr>
                            {taxData.map((row: ObjectKeys, index: number) => (
                                <tr key={index}>
                                    <td>{getMonthName(row.month)}</td>
                                    <td>{formatTwoDecimals(row.sum.toString())}</td>
                                    <td>
                                        {row.status === 'paid' ? (
                                            <div className="icon-container-paid">DA</div>
                                        ) : (
                                            <div className="icon-container-unpaid">NE</div>
                                        )}
                                    </td>
                                    <td>
                                        {row.status === 'paid' ? <div>{formatDate(row.paid_date)}</div> : <div>/</div>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : null}
                </Table>
                {taxData.length > 0 ? (
                    <ButtonContainer>
                        <Button
                            variant={ButtonVariant.solid}
                            color={'var(--purple)'}
                            icon
                            onClick={() => {
                                exportToExcel('download', 'payment-list', 'payment-list.xlsx');
                            }}
                        >
                            <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
                            {t('pages.transfers.payment_lists.export_to_xlsx').text}
                        </Button>
                    </ButtonContainer>
                ) : null}
            </Container>
        </>
    );
};
export default PaymentList;
const Container = styled.div`
    vertical-align: top;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
    .icon-container-paid {
        bottom: 5px;
        color: var(--green);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .icon-container-unpaid {
        bottom: 5px;
        color: red;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .check-icon {
        font-size: 18px;
    }
    .select-year {
        background-color: var(--white);
        color: var(--gray);
        float: right;
        width: fit-content;
    }
    .select-status {
        background-color: var(--white);
        color: var(--gray);
        float: right;
        border-radius: 6px;
    }
`;
const Header = styled.div`
    position: relative;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    .back {
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        vertical-align: top;
        background: var(--white);
        border: 1px solid var(--border-color);
        border-radius: 3px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--black);
        &:hover {
            background: var(--purple);
            border: 1px solid var(--purple);
            color: var(--white);
        }
    }
`;
const TableContainer = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    overflow-x: hidden;

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        overflow-x: scroll;
    }
`;
const Filters = styled.div`
    width: 100%;
    background-color: var(--purple);
    color: var(--white);
    font-size: 14px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
    }
    .second-title {
        font-size: 14px;
        line-height: 34px;
        padding: 0 10px 0 0;
    }
    .filter-single {
        display: flex;
        align-items: center;
        width: 200px;
    }
    .amount-rest {
        max-width: 300px;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-around;
    }
    .third-title {
        font-size: 14px;
        line-height: 34px;
        padding: 0 10px 0 0;
        border: 1px solid var(--white);
        border-radius: 5px;
        margin: 2px;
        padding: 5px;
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    th,
    td {
        border: 1px solid var(--border-color);
        /* padding: 10px; */
        text-align: left;
        background-color: var(--white);
        font-weight: normal;
    }

    tbody td {
        padding: 0;
    }

    th {
        background-color: var(--purple);
        color: var(--white);
    }

    tr:nth-child(even) {
        background-color: var(--light-gray);
    }
    .date-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .checkbox {
            cursor: pointer;
        }
    }
    .gray-style {
        color: rgb(146, 165, 172);
        font-size: 15px;
        line-height: 20px;
        min-height: 5.4em;
        margin-right: 0.83em;
        -webkit-box-flex: 1;
        flex: 1 1 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: normal;
    }
    .link {
        color: var(--purple);
        cursor: pointer;
        font-size: 15px;
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileS}) {
        thead tr th {
            padding: 5px;
        }
        tbody td {
            padding: 5px;
        }
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) {
        thead tr th {
            padding: 10px;
        }
        tbody td {
            padding: 10px;
        }
    }
`;
const ButtonContainer = styled.div`
    button {
        margin-top: 20px;
        float: right;
        font-size: 12px;
        padding: 8px 20px;
        vertical-align: top;
        border-radius: 100px;
    }
`;
const DateWrapper = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    .input {
        > div {
            margin-bottom: 0;
        }
    }
    .text {
        margin-right: 10px;
        margin-top: 10px;
    }
    .checkbox {
        margin-left: 10px;
        cursor: pointer;
        .check-icon {
            border: 1px solid var(--purple);
            border-radius: 5px;
            font-size: 18px;
            color: var(--white);
        }
        .check-icon.checked {
            background-color: var(--purple);
        }
    }
`;
const NoItems = styled.div`
    border-bottom: 1px solid var(--border-color);
    width: 100%;
    > div {
        padding: 60px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    img {
        width: 130px;
    }
    h4 {
        margin: 5px 0;
        text-align: center;
        line-height: 1.4;
    }
    .description {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .link {
        color: var(--purple);
        cursor: pointer;
        :hover {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.laptop}) {
        h4 {
            margin: 10px 0;
        }
    }
`;
