import { FunctionComponent } from 'react';
import styled from 'styled-components';
interface Props {
    value?: string;
    placeholder?: string;
    onChange?: Function;
    label?: string;
    isDisabled?: boolean;
    validation?: string;
    blurHandler?: Function;
    height?: string;
}

const TextArea: FunctionComponent<Props> = ({
    value,
    placeholder,
    label,
    onChange = Function,
    isDisabled,
    validation,
    blurHandler,
    height,
}) => {
    return (
        <StyledWrapper className={`${validation !== undefined && validation !== '' ? 'validation-error' : ''}`.trim()}>
            {label ? <label>{label}</label> : <></>}
            <StyledTextArea
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    onChange(e.target.value);
                    if (blurHandler) blurHandler();
                }}
                value={value}
                placeholder={placeholder}
                disabled={isDisabled}
                onBlur={() => {
                    blurHandler && blurHandler();
                }}
                height={height}
            />
            {validation !== '' && <p className="validation-message">{validation}</p>}
        </StyledWrapper>
    );
};
const StyledWrapper = styled.div<Props>`
    display: flex;
    flex-direction: column;
    label {
        font-size: 15px;
        color: var(--gray);
        margin-bottom: 2px;
    }
    &.validation-error {
        textarea {
            border-bottom: 1px solid var(--danger);
            color: var(--danger);
            &:focus {
                outline: none;
            }
        }
    }
    .validation-message {
        color: var(--danger);
        font-size: 12px;
        margin-top: 2px;
    }
`;
const StyledTextArea = styled.textarea<Props>`
    resize: none;
    font-family: inherit;
    background: transparent;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    border-radius: 2px;
    padding: 11px;
    border: none;
    border-bottom: 1px solid var(--border-color);
    height: ${(props) => props.height || '130px'};
    padding: 5px;
    color: var(--black);
    &:focus {
        outline: none;
        border-bottom: 1px solid var(--purple);
    }
    &::placeholder {
        color: var(--gray);
        font-weight: 400;
    }
`;

export default TextArea;
