import fetch from './fetch';
import routes from './routes';
import { ObjectKeys } from '../types/objectKeys';
import { ChangeAmountTypes } from '../pages/myObligations/transferOrders/manageOrdersModal';
import download from '../communication/download';
import { ChangeAmountTypesAdmin } from '@/components/manageOrdersModalAdmin';
import getCurrentLanguage from '../functions/getCurrentLanguage';

export default class Transfers {
    public getAllTransfers(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'get',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.transfers.getTransfers(),
            params: params,
        });
    }
    public getCallNumber(year: string, id: string): ObjectKeys {
        return fetch({
            method: 'get',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.transfers.getCallNumber(year, id),
        });
    }
    public searchTransfers(search: string): ObjectKeys {
        return fetch({
            method: 'get',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.transfers.searchTransfers(search),
        });
    }

    public getTransferTemplates(year: string, month: string): ObjectKeys {
        return fetch({
            method: 'get',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.transfers.getTransferTemplates(year, month),
        });
    }

    public createTransferOrder(data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.transfers.createTransferOrder(),
            data: data,
        });
    }
    public updateTransferOrder(data: ObjectKeys, id: string): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.transfers.updateTransferOrder(id),
            data: data,
        });
    }
    public createTaxOrder(data: ChangeAmountTypes): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.transfers.createTaxOrder(),
            data: data,
        });
    }

    public getCallNumberByYear(year: string, id: string): ObjectKeys {
        return fetch({
            method: 'get',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.transfers.getCallNumber(year, id),
        });
    }

    public deleteTransferOrder(id: string): ObjectKeys {
        return fetch({
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.transfers.deleteTransferOrder(id),
        });
    }

    public downloadTaxes(year: string, month: string | number, token: string, qr_code: string): ObjectKeys {
        return download(routes.transfers.downloadTaxes(year, month), { token, qr_code }, year, 'pdf');
    }

    public downloadTax(id: string, token: any, qr_code: string): ObjectKeys {
        return download(routes.transfers.downloadTax(id), { token, qr_code }, id, 'pdf');
    }

    //ADMIN API CALLS
    public getTransferByAgencyId(id: string, year: string, month: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.transfers.getTransferByAgencyId(id, year, month),
        });
    }
    public createTransferOrderAdmin(id: string, data: ChangeAmountTypesAdmin): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.transfers.createTransferOrderAdmin(id),
            data: data,
        });
    }

    public downloadTaxesAdmin(id: string, year: string, month: string, token: string, qr_code: string): ObjectKeys {
        return download(routes.transfers.downloadTaxesAdmin(id, year, month), { token, qr_code }, id, 'pdf');
    }

    public downloadTaxAdmin(id: string, token: string, qr_code: string): ObjectKeys {
        return download(routes.transfers.downloadTaxAdmin(id), { token, qr_code }, id, 'pdf');
    }
    public sendTaxEmail(options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            url: routes.admin.tax.sendEmail,
            data: options,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept-Language': getCurrentLanguage(),
            },
        });
    }
}
