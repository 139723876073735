import { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../../../../components/loader';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPrint, faSquare, faCheckSquare, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../../../components/select';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import NoItems from '../../../../components/noItems';
import moment from 'moment';
import communication from '../../../../communication';
import TransferOrderPreviewModal from '../../../../components/transferOrderPreviewModal';
import Modal from '../../../../components/modal';
// import useAgencyStore from '../../../store/agency';
import { ObjectKeys } from '../../../../types/objectKeys';
import TransferOrderAdminCard from '../../../../components/transferOrderAdminCard';
import { getAccessToken } from '../../../../functions/auth';
import { generateQrCode } from '../../../../communication/qr';
import ManageOrdersModalAdmin, { ChangeAmountTypesAdmin } from '../../../../components/manageOrdersModalAdmin';
import ConfirmModal from '../../../../components/confirmModal';
import { range } from 'lodash';
import SearchCustomComponent from '../../../../components/customSelect';
import useUserStore from '../../../../store/user';
import useAllUsersStore from '../../../../store/admin/allUsers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../../../components/sideModal';
import SendEmailModal from './sendEmailModal';
import useResponsive from '../../../../hooks/responsive/useResponsive';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';

export type SelectOption = {
    value: string;
    label: string;
};
const TransferOrdersAdmin: FunctionComponent = () => {
    const { userBOid } = useUserStore();
    const { allUsers } = useAllUsersStore();
    const navigate = useNavigate();
    const paramsYear = useParams().year;
    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string }>>([]);
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [previewModal, setPreviewModal] = useState<boolean>(false);
    const [selectedTransfer, setSelectedTransfer] = useState<ObjectKeys>({});
    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);
    const [transferTemplates, setTransferTemplates] = useState<ObjectKeys>([]);
    const [isInfoModalActive, setIsInfoModalActive] = useState<boolean>(false);
    const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
    const [manageOrdersModalActive, setIsManageOrdersModalActive] = useState<boolean>(false);
    const [selectedUserAgencyId, setSelectedUserAgencyId] = useState<string>('');
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [qrCode, setQrCode] = useState<string>('');
    const [isQrCode, setIsQrCode] = useState<boolean>(false);
    // const agency = useAgencyStore((props) => props.agency);
    const [selectedYear, setSelectedYear] = useState<string>(paramsYear ? paramsYear : moment().year().toString());
    const [selectUsersOptions, setSelectUsersOptions] = useState<SelectOption[]>([]);
    const [users, setUsers] = useState<ObjectKeys[]>([]);
    const [selectedUser, setSelectedUser] = useState<ObjectKeys>();
    const [employed, setEmployed] = useState<boolean>(false);
    const [is_authorised, setIsAuthorised] = useState<boolean>(false);
    const [sendModal, setSendModal] = useState(false);

    const [renderKey, setRenderKey] = useState<number>(0);

    const getCurrentMonthIndex = (): number => {
        const currentDate = moment();
        const currentDayOfMonth = currentDate.date();
        if (currentDayOfMonth <= 15) {
            return currentDate.subtract(1, 'month').month();
        } else {
            return currentDate.month();
        }
    };
    const currentMonthIndex = getCurrentMonthIndex();

    const [currentMonth, setCurrentMonth] = useState<string>(`${currentMonthIndex + 1}`);
    const monthsOptions: Array<{ value: any; label: any }> = [
        { value: '1', label: t('pages.transfers.payment_lists.january').text },
        { value: '2', label: t('pages.transfers.payment_lists.february').text },
        { value: '3', label: t('pages.transfers.payment_lists.march').text },
        { value: '4', label: t('pages.transfers.payment_lists.april').text },
        { value: '5', label: t('pages.transfers.payment_lists.may').text },
        { value: '6', label: t('pages.transfers.payment_lists.june').text },
        { value: '7', label: t('pages.transfers.payment_lists.july').text },
        { value: '8', label: t('pages.transfers.payment_lists.august').text },
        { value: '9', label: t('pages.transfers.payment_lists.september').text },
        { value: '10', label: t('pages.transfers.payment_lists.october').text },
        { value: '11', label: t('pages.transfers.payment_lists.november').text },
        { value: '12', label: t('pages.transfers.payment_lists.december').text },
    ];
    const [data, setData] = useState<ChangeAmountTypesAdmin>({
        year: '',
        months: [],
        user_id: '',
        model_number_recipient: 97,
        call_number_recipient: '',
        total_amount: {
            doprinos_za_zdravstveno_osiguranje: 0,
            doprinos_za_osiguranje_nezaposlenih: 0,
            porez_na_prihode_od_samostalne_delatnosti: 0,
            doprinos_za_pio: 0,
        },
    });

    const { isMobile } = useResponsive();

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + ' - ' + user.pib };
        });
        if (userBOid === '' || userBOid === 'All users') useUserStore.setState({ userBOid: userData[0]?.value });
        setAllUsersOptions([...userData]);
    }, [allUsers]);

    const token = getAccessToken();

    const getAllUsers = async (): Promise<void> => {
        setIsLoader(true);
        const response = await communication.getAllUsers();
        setUsers(response?.data);

        // Reset the select users options before setting new ones
        setSelectUsersOptions(
            response?.data.map((user: { email: any; pib: any; agency_id: any }) => ({
                label: `${user.email} - ${user.pib}`,
                value: user.agency_id,
            })),
        );

        const chosenUser = response.data.find((user: ObjectKeys) => user.id === userBOid);
        setSelectedUser(chosenUser);
        setSelectedUserAgencyId(chosenUser?.agency_id.toString());

        await getAllTransferTemplates();
        setIsLoader(false);
    };

    const getAllTransferTemplates = async (): Promise<void> => {
        setIsLoader(true);
        if (selectedUserAgencyId) {
            const res = await communication.getTransferByAgencyId(selectedUserAgencyId, selectedYear, currentMonth);
            const transferTemplatesData = res.data.data;
            const sortedTransferTemplates = transferTemplatesData.sort((a: ObjectKeys, b: ObjectKeys) => {
                return a.orderTemplates - b.orderTemplates;
            });
            setTransferTemplates(sortedTransferTemplates);
        }
        setIsLoader(false);
    };
    const getAgency = async (): Promise<void> => {
        setIsLoader(true);
        if (selectedUserAgencyId) {
            const res = await communication.getAgencyById(selectedUserAgencyId);
            setEmployed(res?.data?.data?.employment_type !== 'flat_rate_agency' ? true : false);
            setIsAuthorised(res?.data?.data?.user?.data?.authorised);

            const thisYear = parseInt(moment().format('YYYY'));
            const firstYear = parseInt(moment(res.data.data?.date_of_registration).format('YYYY'));
            const yearsArray = range(firstYear, thisYear + 1)
                .reverse()
                .map((y) => {
                    return { label: y, value: y };
                });
            setYearsOptions(yearsArray);
        }
        setIsLoader(false);
    };

    useEffect(() => {
        getAllUsers();
        setIsInfoModalActive(true);
    }, []);

    // useEffect(() => {
    //     navigate(`/admin/transfer-orders/${selectedYear}`);
    //     getAllTransferTemplates();
    // }, [selectedYear, currentMonth]);
    // useEffect(() => {
    //     getAgency();
    //     getAllTransferTemplates();
    //     setSelectedYear(moment().year().toString());
    //     setRenderKey(renderKey + 2);
    //     setCurrentMonth(monthsOptions[currentMonthIndex].value);
    // }, [selectedUserAgencyId]);

    useEffect(() => {
        let isCancelled = false;
        const effectFunction = async (): Promise<void> => {
            if (!isCancelled) {
                navigate(`/admin/transfer-orders/${selectedYear}`);
                await getAllTransferTemplates();
            }
        };
        effectFunction();
        return () => {
            isCancelled = true;
        };
    }, [selectedYear, currentMonth]);

    useEffect(() => {
        let isCancelled = false;
        const effectFunction = async (): Promise<void> => {
            if (!isCancelled) {
                await getAgency();
                await getAllTransferTemplates();
                setSelectedYear(moment().year().toString());
                setRenderKey(renderKey + 2);
                setCurrentMonth(monthsOptions[currentMonthIndex].value);
            }
        };
        effectFunction();
        return () => {
            isCancelled = true;
        };
    }, [selectedUserAgencyId]);

    useEffect(() => {
        if (paramsYear) {
            setSelectedYear(paramsYear);
        }
    }, [paramsYear]);

    const downloadTaxes = async (): Promise<void> => {
        try {
            const qrCodes = await getQrCodesForPrint();
            communication.downloadTaxesAdmin(
                selectedUser?.id,
                selectedYear,
                currentMonth,
                token,
                JSON.stringify(qrCodes),
            );
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const downloadTax = async (transfer: ObjectKeys): Promise<void> => {
        try {
            const qrCodeR = await getQrCode(transfer);
            communication.downloadTaxAdmin(transfer?.id, token, `data:image/jpeg;base64,${qrCodeR}`);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const getQrCode = async (transfer: ObjectKeys): Promise<string | undefined> => {
        try {
            const res: any = await generateQrCode(
                transfer?.recipient,
                transfer?.orderer,
                transfer.total_amount,
                transfer.purpose_of_payment,
                transfer.recipient_account,
                transfer.currency,
                true,
                `${transfer.model_number_recipient === '97' ? transfer.model_number_recipient : '00'}${
                    transfer.call_number_recipient
                }`,
                transfer.payment_code,
            );
            setQrCode(res?.i);
            setIsQrCode(true);

            return res?.i;
        } catch (error) {
            setQrCode('');
            console.error('An error occurred:', error);
            return undefined;
        }
    };

    const getQrCodesForPrint = async (): Promise<{ [key: string]: string }> => {
        const qrCodes: { [key: string]: string } = {};
        const qrCodePromises: Promise<void>[] = [];
        for (const transfer of transferTemplates as any[]) {
            qrCodePromises.push(
                (async () => {
                    try {
                        qrCodes[transfer.id] = '""';
                        const res: any = await generateQrCode(
                            transfer?.recipient,
                            transfer?.orderer,
                            transfer.total_amount,
                            transfer.purpose_of_payment,
                            transfer.recipient_account,
                            transfer.currency,
                            true,
                            `${transfer.model_number_recipient === '97' ? transfer.model_number_recipient : '00'}${
                                transfer.call_number_recipient
                            }`,
                            transfer.payment_code,
                        );
                        qrCodes[transfer.id] = res?.i ? res.i : '""';
                    } catch (err) {
                        console.error(err);
                    }
                })(),
            );
        }
        await Promise.all(qrCodePromises);
        return qrCodes;
    };

    const sendNewTaxOrder = async (): Promise<void> => {
        setIsLoader(true);
        const formattedData = {
            ...data,
            total_amount: {
                doprinos_za_zdravstveno_osiguranje: Number(
                    data.total_amount.doprinos_za_zdravstveno_osiguranje.toString().replace(',', '.'),
                ),
                doprinos_za_osiguranje_nezaposlenih: Number(
                    data.total_amount.doprinos_za_osiguranje_nezaposlenih.toString().replace(',', '.'),
                ),
                porez_na_prihode_od_samostalne_delatnosti: Number(
                    data.total_amount.porez_na_prihode_od_samostalne_delatnosti.toString().replace(',', '.'),
                ),
                doprinos_za_pio: Number(data.total_amount.doprinos_za_pio.toString().replace(',', '.')),
            },
        };
        const res = await communication.createTransferOrderAdmin(selectedUser?.agency_id, formattedData);
        if (res.status === 200) {
            getAllTransferTemplates().then(() => {
                const payload = {
                    month: data.months,
                    year: selectedYear,
                    status: 'pending',
                    paid_date: new Date().toISOString().split('T')[0],
                    user_id: selectedUser?.id,
                };
                communication.sendTaxCalendar(payload);
            });
        }
        setIsConfirmModal(false);
        setIsLoader(false);
    };
    const handleEservice = async (e: boolean): Promise<void> => {
        setIsLoader(true);
        const res = await communication.updateUser({ authorised: e }, selectedUser?.id);
        if (res.status === 200) {
            setIsAuthorised(res.data.data.authorised);
            setIsLoader(false);
            toast.success('Uspešno ste promenili ovlašćenje za korisnika');
            getAllUsers();
        } else {
            toast.error('Došlo je do greške prilikom promene ovlašćenja za korisnika');
        }
    };

    return (
        <PageWrapper>
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendEmailModal
                        close={() => setSendModal(false)}
                        t={t}
                        success={() => {
                            toast.success('E-mail je uspešno poslat.');
                        }}
                        loading={(e: boolean) => setIsLoader(e)}
                        clientEmail={selectedUser?.email}
                        errorMessage={(mess: string) => {
                            toast.error(mess);
                        }}
                    />
                </Sidebar>
            )}
            {isLoader && <Loader />}
            <ToastContainer />
            {previewModal && (
                <Modal
                    modalVisible={previewModal}
                    closeModal={() => {
                        setPreviewModal(false);
                        setIsQrCode(false);
                    }}
                    size={'800px'}
                    className={'preview-modal '}
                >
                    <TransferOrderPreviewModal
                        image={qrCode}
                        onClose={() => {
                            setPreviewModal(false);
                        }}
                        transfer={selectedTransfer}
                        isQrCode={isQrCode}
                        downloadTax={downloadTax}
                    />
                </Modal>
            )}
            <Modal
                className={'manage-orders-modal '}
                modalVisible={isConfirmModal}
                closeModal={() => setIsConfirmModal(false)}
            >
                <ConfirmModal action={sendNewTaxOrder} close={() => setIsConfirmModal(false)}>
                    <div className={'info-modal-content'}>
                        <h2>{t('pages.transfers.changing_user_title').text}</h2>
                        <p>
                            {t('pages.transfers.changing_user_name').text} {selectedUser?.first_name}
                        </p>
                        <p>
                            {t('pages.transfers.changing_user_last_name').text} {selectedUser?.last_name}
                        </p>
                        <p>
                            {t('pages.transfers.changing_user_email').text} {selectedUser?.email}
                        </p>
                        <p>
                            {t('pages.transfers.changing_user_pib').text} {selectedUser?.pib}
                        </p>
                    </div>
                </ConfirmModal>
            </Modal>

            {manageOrdersModalActive && (
                <Modal
                    className={'manage-orders-modal '}
                    modalVisible={manageOrdersModalActive}
                    closeModal={() => setIsManageOrdersModalActive(false)}
                >
                    <ManageOrdersModalAdmin
                        closeModal={() => setIsManageOrdersModalActive(false)}
                        yearsOptions={yearsOptions}
                        setIsConfirmModal={setIsConfirmModal}
                        user={selectedUser}
                        setData={setData}
                        year={selectedYear}
                        callNumber={transferTemplates.length > 0 ? transferTemplates[0].call_number_recipient : ''}
                        transferOrders={transferTemplates}
                        isEmployed={employed}
                        currentMonth={currentMonth}
                    />
                </Modal>
            )}

            <Modal
                className={'info-modal '}
                modalVisible={isInfoModalActive}
                closeModal={() => setIsInfoModalActive(false)}
            >
                <div className={'info-modal-content'}>
                    <h2>{t('pages.transfers.changing_user_title').text}</h2>
                    <p>
                        {t('pages.transfers.changing_user_name').text} {selectedUser?.first_name}
                    </p>
                    <p>
                        {t('pages.transfers.changing_user_last_name').text} {selectedUser?.last_name}
                    </p>
                    <p>
                        {t('pages.transfers.changing_user_email').text} {selectedUser?.email}
                    </p>
                    <p>
                        {t('pages.transfers.changing_user_pib').text} {selectedUser?.pib}
                    </p>
                </div>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    size={'100%'}
                    onClick={() => {
                        setIsInfoModalActive(false);
                    }}
                >
                    {t(['buttons.ok']).text}
                </Button>
            </Modal>
            <SelectUser>
                <label>{t('pages.transfers.title').text}</label>
                {selectUsersOptions?.length > 0 && (
                    <SearchCustomComponent
                        value={allUsersOptions.find((v) => v.value === userBOid)}
                        allUsersOptions={selectUsersOptions}
                        handleSelectedValue={(datas: { value: string; label: string }) => {
                            const userId = allUsers.find((user: ObjectKeys) => user.agency_id === datas.value)?.id;
                            useUserStore.setState({ userBOid: userId });
                            setSelectedUserAgencyId(datas.value);
                            users.map((user: ObjectKeys) => {
                                user?.agency_id === datas.value && setSelectedUser(user);
                            });

                            setIsInfoModalActive(true);
                        }}
                        placeholder={t('pages.admin.users.chooseUser').text}
                        className="user-selector"
                    />
                )}
            </SelectUser>
            <CheckBoxContainer>
                <p>OVLAŠĆENJE ZA UPOTREBU E-SERVISA</p>
                <div
                    onClick={() => {
                        handleEservice(!is_authorised);
                    }}
                    className="checkbox"
                >
                    <FontAwesomeIcon icon={is_authorised ? faCheckSquare : faSquare} className="check-icon" />
                </div>
            </CheckBoxContainer>
            <Header>
                <h1>{t('pages.transfers.title').text}</h1>
                <div className="header-right">
                    <div className="header-options">
                        <div className="header-options-buttons">
                            <Button
                                className={'button'}
                                variant={ButtonVariant.outlined}
                                color={colors.purple}
                                onClick={() => {
                                    setSendModal(true);
                                }}
                                icon={true}
                                height={35}
                            >
                                <FontAwesomeIcon icon={faPaperPlane} />
                                {'Pošalji mejl'}
                            </Button>
                            <Button
                                className={'button'}
                                variant={ButtonVariant.outlined}
                                color={colors.purple}
                                onClick={() => downloadTaxes()}
                                icon={true}
                                height={35}
                            >
                                <FontAwesomeIcon icon={faPrint} />
                                {t('pages.transfers.print_transfers').text}
                            </Button>
                            <Button
                                className={'button'}
                                variant={ButtonVariant.outlined}
                                color={colors.purple}
                                onClick={() => setIsManageOrdersModalActive(true)}
                                icon={true}
                                height={35}
                            >
                                <FontAwesomeIcon icon={faPencil} />
                                {t('pages.transfers.manage_transfers').text}
                            </Button>
                        </div>
                    </div>
                </div>
                <Filters>
                    <label>{`${t('pages.transfers.filter_first_description').text} ${selectedYear}`}</label>
                    <div className="search-filter">
                        <SelectComponent
                            optionList={monthsOptions}
                            defaultSelectedOption={monthsOptions[currentMonthIndex]}
                            className={'select-month'}
                            handleSelectedValue={(value: string) => {
                                setCurrentMonth(value);
                            }}
                            key={renderKey}
                        />
                        <SelectComponent
                            optionList={yearsOptions}
                            defaultSelectedOption={yearsOptions.find((option) => option.value === selectedYear)}
                            className={'select-year'}
                            placeholder={selectedYear}
                            handleSelectedValue={(value: string) => {
                                navigate(`/admin/transfer-orders/${selectedYear}`);
                                setSelectedYear(value);
                            }}
                            key={selectedYear}
                        />
                    </div>
                </Filters>
            </Header>

            {transferTemplates.length === 0 && (
                <NoItems text={`${t('pages.transfers.no_tax_transfers').text} ${selectedYear}.`} />
            )}

            <TransferOrderTemplateContainer>
                {transferTemplates?.map((template: object, index: number) => (
                    <TransferOrderAdminCard
                        className={isMobile ? 'mobile' : ''}
                        key={index}
                        template={template}
                        onClick={() => {
                            getQrCode(template);
                            setSelectedTransfer(template);
                            setPreviewModal(true);
                        }}
                    />
                ))}
            </TransferOrderTemplateContainer>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    min-height: 100vh;
    display: inline-block;
    vertical-align: top;
    width: -webkit-fill-available;
    width: -moz-available;
    .button-add {
        margin-top: 20px;
    }
    .preview-modal {
        .modal-wrapper {
            width: 800px;
            padding: 15px;
            overflow: auto;
            max-height: 100vh;
        }
    }
    .manage-orders-modal {
        .modal-wrapper {
            max-height: 90%;
            overflow: auto;
        }
    }
    .info-modal-content {
        display: block;
        padding: 35px 50px 30px;
        text-align: center;
        min-height: 130px;
        h2 {
            font-size: 20px;
            color: #4c595f;
            font-weight: 400;
            margin-bottom: 30px;
            line-height: 1.5;
        }
        p {
            font-size: 16px;
            margin-top: 5px;
            line-height: 30px;
            white-space: pre-line;
        }
    }
`;

const SelectUser = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    label {
        display: inline-block;
        color: #92a5ac;
        font-size: 16px;
        position: relative;
        top: 2px;
        margin-right: 10px;
    }
    .select-wrapper {
        width: max-content;
        min-width: 257px;
        .select__menu {
            width: 100%;
        }
    }
`;

const TransferOrderTemplateContainer = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto 25px;
    width: 100%;
`;
const CheckBoxContainer = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto 25px;
    width: 100%;
    p {
        margin-right: 10px;
        color: var(--gray);
    }
    .checkbox {
        cursor: pointer;
        .check-icon {
            color: var(--purple);
            border-radius: 5px;
            font-size: 18px;
        }
    }
`;

const Header = styled.div`
    padding-bottom: 20px;
    position: relative;
    display: inline-block;
    width: 100%;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
    }
    h1 {
        font-size: 36px;
        display: inline-block;
    }
    button {
        font-size: 12px;
    }
    .header-right {
        float: right;
        margin: 0 0 0 35px;
    }
    .header-options-button {
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
    .input-container {
        margin-bottom: 0;
    }
    .find {
        background-color: var(--white);
        padding: 20px 0px 20px 30px;
        border: none;
    }
    .find-icon {
        position: relative;
        align-self: flex-start;
        justify-self: center;
        bottom: 27px;
        left: 9px;
    }
    .header-options {
        display: flex;
    }
    .header-options-buttons {
        display: flex;
        button {
            margin-left: 20px;
        }
    }
`;

const Filters = styled.div`
    height: 80px;
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    padding: 20px 20px;
    background-color: var(--purple);
    color: var(--white);
    vertical-align: middle;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        height: auto;
    }
    .select {
        width: 100%;
        display: inline-block;
        background-color: var(--white);
    }
    .select-wrapper {
        display: inline-block;
    }
    .filter {
        display: flex;
        float: right;
        align-items: center;
        margin-right: 10px;
        max-width: 200px;
        @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            width: 80px;
        }
    }
    .select-month {
        background-color: var(--white);
        color: var(--gray);
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            float: none;
        }
    }

    .search-filter {
        display: flex;
        float: right;
        margin-right: 10px;
        right: 0;
        top: 78px;
        width: 250px;
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
            margin-right: 0;
        }
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            width: 100%;
            float: left;
            margin-top: 20px;
            .select-year {
                float: left;
            }
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
        .select-wrapper {
            display: inline-block;
        }
    }
    .select-year {
        background-color: var(--white);
        color: var(--gray);
        float: right;
        width: fit-content;
    }
`;

export default TransferOrdersAdmin;
