import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
import useInvoiceParamsStore from '../../../store/invoiceParams';

const getAllAInvoices = (setInvoices: any): void => {
    const params = useInvoiceParamsStore.getState().eadvances_params;
    communication.getInvoices(params).then((res: ObjectKeys) => {
        if (res.status === 200) {
            setInvoices(res?.data);
        }
    });
};
export default getAllAInvoices;
