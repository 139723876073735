import { FunctionComponent, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faClone,
    faTrashCan,
    faEnvelope,
    faCheckCircle,
    faE,
    faEllipsisVertical,
    faMinusCircle,
    faRefresh,
    faBell,
    faEraser,
} from '@fortawesome/free-solid-svg-icons';
import colors from '../../global/colors';
import { formatNumber } from '../../functions/format';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import { ObjectKeys } from '../../types/objectKeys';
import { Link } from 'react-router-dom';
import useDropdown from '../invoiceCard/useDropdown';
import Dropdown from '../dropdown';
import getStatus from '../invoiceCard/getStatus';
import { Icon } from '../invoiceCard/invoiceActionsWithoutDropdown';
import useProfileStore from '../../store/profile';
import PreviewInvoiceModalPlain from '../../pages/pausal/common/previewInvoiceModalPlain';
import PreviewInvoiceModalArtistic from '../../pages/pausal/common/previewInvoiceModalArtistic';
import PreviewInvoiceModalBusiness from '../../pages/pausal/common/previewInvoiceModalBusiness';
import PreviewInvoiceModalInvoicePro from '../../pages/pausal/common/previewInvoiceModalInvoicePro';
import PreviewInvoiceModalPlain2 from '../../pages/pausal/common/previewInvoiceModalPlain2';
import useUserStore from '../../store/user';
import useAgencyStore from '../../store/agency';
import Modal from '../modal';
import { generateQrCode } from '../../communication/qr';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';
import useResponsive from '../../hooks/responsive/useResponsive';
import {
    StyledInvoiceCard,
    CardContent,
    InfoWrapper,
    ActionWrapper,
    Action,
    DropdownContainer,
    ActionWithoutDropdown,
} from '../invoiceCard/style';
import Tooltip from '../invoiceCard/tooltip';

export enum EInvoiceCardComponentType {
    Invoice = 'invoice',
    EInvoice = 'eInvoice',
    Proforma = 'proforma',
    AdvanceInvoices = 'advanceInvoices',
}
interface Props {
    type: string;
    className?: string;
    handleChange?: Function;
    handleDownload?: Function;
    handleCopy?: Function;
    handleCancel?: Function;
    handleDelete?: Function;
    handleSend?: Function;
    handleReminder?: Function;
    handlePayment?: Function;
    handleEInvoice?: Function;
    handleClick: Function;
    handleRefresh?: Function;
    cardDisplay: string;
    cardData: ObjectKeys;
    eInvoice?: boolean;
    handleRemovePayments?: Function;
    dropdownDirection?: 'up' | 'down';
}
const EInvoiceCard: FunctionComponent<Props> = ({
    type,
    className,
    handleChange,
    handleCancel,
    handleCopy,
    handleDelete,
    handleDownload,
    handlePayment,
    handleSend,
    handleReminder,
    handleClick,
    handleRefresh,
    cardDisplay,
    cardData,
    eInvoice,
    handleRemovePayments,
    dropdownDirection = 'down',
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const ref = useRef<HTMLDivElement>(null);
    const { profile } = useProfileStore();
    const { isLaptopAndDown } = useResponsive();

    const isAccountActive = profile?.active === 1 ? true : false;

    const isAdvance = cardData?.is_prepaid ? true : false;
    const eAdvanceWithPrepaids = cardData.is_einvoice && cardData?.prepaid_invoices?.length > 0 ? false : true;
    const isCanceledPaidInvoice = cardData.status === 'paid' && cardData.cancelled ? false : true;

    const [previewModal, setPreviewModal] = useState(false);
    const { agency } = useAgencyStore();
    const { userInfo } = useUserStore();
    const preferredTemplate = userInfo?.preferred_template?.data.display_name;
    const [qrCode, setQrCode] = useState('');

    const [dropdownVisible, setDropdownVisible] = useState(false);

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(
                agencyData,
                clientData,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
                `00${invoiceNumber?.replace('/', '-')}`,
                '221',
            )
                .then((response: ObjectKeys | any) => {
                    if (response.i) setQrCode(response.i);
                })
                .catch((error: any) => {
                    setQrCode('');
                    if (error) {
                        console.error(error);
                    }
                });
        } catch (e: any) {
            setQrCode('');
        }
    };

    const paidDropdown = useDropdown(
        cardData,
        type,
        'paid',
        undefined,
        undefined,
        handleCopy,
        undefined,
        handleSend,
        undefined,
        undefined,
        handleDownload,
        undefined,
        undefined,
        undefined,
        handleRefresh,
        eInvoice,
        handleRemovePayments,
        isAdvance,
    );
    const sentDropdown = useDropdown(
        cardData,
        type,
        'sent',
        undefined,
        handleCancel,
        handleCopy,
        undefined,
        handleSend,
        handleReminder,
        handlePayment,
        handleDownload,
        undefined,
        undefined,
        undefined,
        handleRefresh,
        eInvoice,
        undefined,
        isAdvance,
    );
    const draftDropdown = useDropdown(
        cardData,
        type,
        'draft',
        undefined,
        undefined,
        handleCopy,
        undefined,
        handleSend,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        handleRefresh,
        eInvoice,
        undefined,
        isAdvance,
    );
    const partiallyDropdown = useDropdown(
        cardData,
        type,
        'partially',
        undefined,
        undefined,
        handleCopy,
        undefined,
        handleSend,
        undefined,
        handlePayment,
        handleDownload,
        undefined,
        undefined,
        undefined,
        handleRefresh,
        eInvoice,
        handleRemovePayments,
        isAdvance,
    );
    const cancelledDropdown = useDropdown(
        cardData,
        type,
        'cancelled',
        undefined,
        handleCancel,
        handleCopy,
        undefined,
        handleSend,
        undefined,
        handlePayment,
        handleDownload,
        undefined,
        undefined,
        undefined,
        handleRefresh,
        eInvoice,
        undefined,
        isAdvance,
    );

    // On outside click close the menu
    const handleClickOutside = (event: MouseEvent): void => {
        if (!ref?.current?.contains(event.target as HTMLDivElement)) {
            setDropdownVisible(false);
        }
    };

    const handleCardClick = (): void => {
        if (!isAccountActive) {
            handleDownload && handleDownload(cardData.id);
        } else {
            setPreviewModal(true);
            if (cardData.type !== 'foreign' && cardData.is_prepaid !== 1) {
                handleGenerateQRCode(
                    agency.full_name,
                    cardData?.client?.data?.company_name,
                    cardData?.total_for_payment_in_rsd,
                    cardData?.invoice_number ? cardData?.invoice_number : cardData?.quotation_number,
                    cardData?.bank_account,
                    cardData?.currency,
                    true,
                );
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <StyledInvoiceCard key={cardData.id} className={`card ${className ?? ''} card-display-${cardDisplay}`.trim()}>
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    hasCloseButton={true}
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                >
                    {preferredTemplate === 'Plain' && (
                        <PreviewInvoiceModalPlain
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'Artistic' && (
                        <PreviewInvoiceModalArtistic
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'Business' && (
                        <PreviewInvoiceModalBusiness
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'InvoicePro' && (
                        <PreviewInvoiceModalInvoicePro
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'Plain 2' && (
                        <PreviewInvoiceModalPlain2
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                </Modal>
            )}
            <CardContent className="card-content cursor-pointer">
                <InfoWrapper
                    onClick={() => {
                        handleCardClick();
                    }}
                    className={`info-wrapper ${cardDisplay === 'grid' && 'grid-view'}`.trim()}
                >
                    <div className="name-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.name').text}</div>
                        <div className="name">{cardData?.client?.data?.company_name}</div>
                    </div>
                    <div className="number-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.prefix').text}</div>
                        <div className="prefix">{cardData?.prefix}</div>
                    </div>
                    <div className="number-col">
                        <div className="label">
                            {t('pages.invoiceCard.infoWrapper.number').text +
                                ': ' +
                                (type === 'invoice' ? t('pages.invoiceCard.infoWrapper.invoice').text : '')}
                        </div>
                        <div className="number">
                            {cardData?.invoice_number
                                ? cardData?.invoice_number
                                : getStatus(cardData?.status?.toLowerCase(), t)}
                        </div>
                    </div>
                    <div className="date-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.date').text}</div>
                        <div className="date">{moment(cardData?.invoice_date)?.format('DD.MM.YYYY')}</div>
                    </div>
                    <div className="amount-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.amount').text}</div>
                        <div className="amount">
                            {formatNumber(cardData?.value, 2, 3, '.', ',') + ' ' + cardData?.currency}
                        </div>
                    </div>
                    <div className="statusAndAction">
                        <div className="e-invoice-status-wrapper">
                            <div className="status-col">
                                <div className="label">{t('pages.invoiceCard.infoWrapper.status').text}</div>
                                <div className={`status ${cardData?.status}`}>
                                    <span>{getStatus(cardData?.status?.toLowerCase(), t)}</span>
                                </div>
                            </div>
                            {eInvoice &&
                                (() => {
                                    const statusRaw = cardData?.eInvoice?.data?.status;
                                    const status = statusRaw ? statusRaw.toLowerCase() : null;
                                    return (
                                        <div className="status-col">
                                            <div className="label">
                                                {isAdvance
                                                    ? t('pages.eInvoices.status.title_eAdvance').text
                                                    : t('pages.eInvoices.status.title').text}
                                            </div>
                                            <div className={`estatus ${status}`}>
                                                <span>{getStatus(status, t, eInvoice)}</span>
                                            </div>
                                        </div>
                                    );
                                })()}
                        </div>
                        {isAccountActive ? (
                            <ActionWrapper className="mainActionWrapper" onClick={(e) => e.stopPropagation()}>
                                <div className="label"></div>
                                <div className="action">
                                    {isLaptopAndDown ? (
                                        <Action>
                                            <FontAwesomeIcon
                                                icon={faEllipsisVertical}
                                                style={{ color: colors.gray }}
                                                className="cursor-pointer dots"
                                                onClick={() => {
                                                    handleClick(cardData?.id);
                                                    setDropdownVisible(!dropdownVisible);
                                                }}
                                            />
                                            {dropdownVisible && (
                                                <DropdownContainer
                                                    ref={ref}
                                                    onClick={(e: MouseEvent | any) => {
                                                        handleClickOutside(e);
                                                        setDropdownVisible(false);
                                                    }}
                                                    className={dropdownDirection}
                                                >
                                                    <Dropdown
                                                        arrowRight="4px"
                                                        caretDown={dropdownDirection === 'up'}
                                                        itemList={
                                                            cardData?.status === 'paid'
                                                                ? paidDropdown
                                                                : cardData?.status === 'sent'
                                                                ? sentDropdown
                                                                : cardData?.status === 'open'
                                                                ? draftDropdown
                                                                : cardData?.status === 'partially'
                                                                ? partiallyDropdown
                                                                : cardData?.status === 'cancelled' ||
                                                                  cardData?.status === 'closed'
                                                                ? cancelledDropdown
                                                                : []
                                                        }
                                                    />
                                                </DropdownContainer>
                                            )}
                                        </Action>
                                    ) : (
                                        <ActionWithoutDropdown onClick={(e) => e.stopPropagation()}>
                                            {cardData?.status === 'paid' ? (
                                                <>
                                                    {eInvoice && (
                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.refresh').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faRefresh}
                                                                    style={{ color: colors.purple }}
                                                                    onClick={(e) => handleRefresh && handleRefresh(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}

                                                    <Tooltip text={t('pages.invoiceCard.dropdownItems.download').text}>
                                                        <Icon>
                                                            <FontAwesomeIcon
                                                                icon={faDownload}
                                                                style={{ color: colors.blue }}
                                                                onClick={(e) => handleDownload && handleDownload(e)}
                                                            />
                                                        </Icon>
                                                    </Tooltip>

                                                    <Link to={`/e-invoices/edit/${cardData.id}`}>
                                                        <Tooltip text={t('pages.invoiceCard.dropdownItems.copy').text}>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faClone}
                                                                    style={{ color: colors.purple }}
                                                                    onClick={(e) => handleCopy && handleCopy(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Link>

                                                    {!eInvoice && (
                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.delete').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faTrashCan}
                                                                    style={{ color: colors.danger }}
                                                                    onClick={(e) => handleDelete && handleDelete(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}

                                                    <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                                                        <Icon>
                                                            <FontAwesomeIcon
                                                                icon={faEnvelope}
                                                                style={{ color: colors.blue }}
                                                                onClick={(e) => handleSend && handleSend(e)}
                                                            />
                                                        </Icon>
                                                    </Tooltip>

                                                    <Tooltip>
                                                        <Icon>
                                                            <FontAwesomeIcon
                                                                icon={faE}
                                                                style={{ color: colors.gray }}
                                                            />
                                                        </Icon>
                                                    </Tooltip>

                                                    {eAdvanceWithPrepaids && isCanceledPaidInvoice && (
                                                        <Tooltip
                                                            text={
                                                                t('pages.invoiceCard.dropdownItems.removePayment').text
                                                            }
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faEraser}
                                                                    style={{ color: colors.danger }}
                                                                    onClick={(e) =>
                                                                        handleRemovePayments && handleRemovePayments(e)
                                                                    }
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            ) : cardData?.status === 'open' ? (
                                                <>
                                                    <Link to={`/e-invoices/edit/${cardData.id}`}>
                                                        <Tooltip text={t('pages.invoiceCard.dropdownItems.copy').text}>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faClone}
                                                                    style={{ color: colors.purple }}
                                                                    onClick={(e) => handleCopy && handleCopy(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Link>

                                                    {!eInvoice && (
                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.delete').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faTrashCan}
                                                                    style={{ color: colors.danger }}
                                                                    onClick={(e) => handleDelete && handleDelete(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}

                                                    <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                                                        <Icon>
                                                            <FontAwesomeIcon
                                                                icon={faEnvelope}
                                                                style={{ color: colors.blue }}
                                                                onClick={(e) => handleSend && handleSend(e)}
                                                            />
                                                        </Icon>
                                                    </Tooltip>

                                                    {eInvoice && (
                                                        <Tooltip>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faE}
                                                                    style={{ color: colors.gray }}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            ) : cardData?.status === 'sent' ? (
                                                <>
                                                    {eInvoice &&
                                                        (cardData.eInvoice.data.status === 'Sent' ||
                                                            cardData.eInvoice.data.status === 'Sending' ||
                                                            cardData.eInvoice.data.status === 'Approved' ||
                                                            cardData.eInvoice.data.status === 'Rejected') && (
                                                            <Tooltip
                                                                text={t('pages.invoiceCard.dropdownItems.refresh').text}
                                                            >
                                                                <Icon>
                                                                    <FontAwesomeIcon
                                                                        icon={faRefresh}
                                                                        style={{ color: colors.purple }}
                                                                        onClick={(e) =>
                                                                            handleRefresh && handleRefresh(e)
                                                                        }
                                                                    />
                                                                </Icon>
                                                            </Tooltip>
                                                        )}

                                                    <Tooltip text={t('pages.invoiceCard.dropdownItems.download').text}>
                                                        <Icon>
                                                            <FontAwesomeIcon
                                                                icon={faDownload}
                                                                style={{ color: colors.blue }}
                                                                onClick={(e) => handleDownload && handleDownload(e)}
                                                            />
                                                        </Icon>
                                                    </Tooltip>

                                                    <Link to={`/e-invoices/edit/${cardData.id}`}>
                                                        <Tooltip text={t('pages.invoiceCard.dropdownItems.copy').text}>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faClone}
                                                                    style={{ color: colors.purple }}
                                                                    onClick={(e) => handleCopy && handleCopy(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Link>
                                                    {cardData?.eInvoice?.data?.status === 'Sending' ? null : (
                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.cancel').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faMinusCircle}
                                                                    style={{ color: colors.gray }}
                                                                    onClick={(e) => handleCancel && handleCancel(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}

                                                    {!eInvoice && (
                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.delete').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faTrashCan}
                                                                    style={{ color: colors.danger }}
                                                                    onClick={(e) => handleDelete && handleDelete(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}

                                                    <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                                                        <Icon>
                                                            <FontAwesomeIcon
                                                                icon={faEnvelope}
                                                                style={{ color: colors.blue }}
                                                                onClick={(e) => handleSend && handleSend(e)}
                                                            />
                                                        </Icon>
                                                    </Tooltip>

                                                    {!isAdvance && (
                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.payment').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faCheckCircle}
                                                                    style={{ color: colors.green }}
                                                                    onClick={(e) => handlePayment && handlePayment(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}

                                                    <Tooltip>
                                                        <Icon>
                                                            <FontAwesomeIcon
                                                                icon={faE}
                                                                style={{ color: colors.gray }}
                                                            />
                                                        </Icon>
                                                    </Tooltip>

                                                    {!isAdvance && (
                                                        <Tooltip
                                                            text={t('pages.invoices.sendModal.send_reminder').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faBell}
                                                                    style={{ color: colors.purple }}
                                                                    onClick={(e) => handleReminder && handleReminder(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            ) : cardData?.status === 'partially' ? (
                                                <>
                                                    {eInvoice && cardData.eInvoice.data.status === 'Sent' && (
                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.refresh').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faRefresh}
                                                                    style={{ color: colors.purple }}
                                                                    onClick={(e) => handleRefresh && handleRefresh(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}

                                                    <Tooltip text={t('pages.invoiceCard.dropdownItems.download').text}>
                                                        <Icon>
                                                            <FontAwesomeIcon
                                                                icon={faDownload}
                                                                style={{ color: colors.blue }}
                                                                onClick={(e) => handleDownload && handleDownload(e)}
                                                            />
                                                        </Icon>
                                                    </Tooltip>

                                                    <Link to={`/e-invoices/edit/${cardData.id}`}>
                                                        <Tooltip text={t('pages.invoiceCard.dropdownItems.copy').text}>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faClone}
                                                                    style={{ color: colors.purple }}
                                                                    onClick={(e) => handleCopy && handleCopy(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Link>

                                                    {!eInvoice && (
                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.delete').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faTrashCan}
                                                                    style={{ color: colors.danger }}
                                                                    onClick={(e) => handleDelete && handleDelete(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}

                                                    <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                                                        <Icon>
                                                            <FontAwesomeIcon
                                                                icon={faEnvelope}
                                                                style={{ color: colors.blue }}
                                                                onClick={(e) => handleSend && handleSend(e)}
                                                            />
                                                        </Icon>
                                                    </Tooltip>

                                                    {!isAdvance && (
                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.payment').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faCheckCircle}
                                                                    style={{ color: colors.green }}
                                                                    onClick={(e) => handlePayment && handlePayment(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}

                                                    {eInvoice && (
                                                        <Tooltip>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faE}
                                                                    style={{ color: colors.gray }}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}

                                                    {eAdvanceWithPrepaids && isCanceledPaidInvoice && (
                                                        <Tooltip
                                                            text={
                                                                t('pages.invoiceCard.dropdownItems.removePayment').text
                                                            }
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faEraser}
                                                                    style={{ color: colors.danger }}
                                                                    onClick={(e) =>
                                                                        handleRemovePayments && handleRemovePayments(e)
                                                                    }
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            ) : cardData?.status === 'cancelled' || cardData?.status === 'closed' ? (
                                                <>
                                                    {eInvoice && (
                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.refresh').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faRefresh}
                                                                    style={{ color: colors.purple }}
                                                                    onClick={(e) => handleRefresh && handleRefresh(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}

                                                    <Tooltip text={t('pages.invoiceCard.dropdownItems.download').text}>
                                                        <Icon>
                                                            <FontAwesomeIcon
                                                                icon={faDownload}
                                                                style={{ color: colors.blue }}
                                                                onClick={(e) => handleDownload && handleDownload(e)}
                                                            />
                                                        </Icon>
                                                    </Tooltip>

                                                    <Link to={`/e-invoices/edit/${cardData.id}`}>
                                                        <Tooltip text={t('pages.invoiceCard.dropdownItems.copy').text}>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faClone}
                                                                    style={{ color: colors.purple }}
                                                                    onClick={(e) => handleCopy && handleCopy(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Link>

                                                    {!eInvoice && (
                                                        <Tooltip
                                                            text={t('pages.invoiceCard.dropdownItems.delete').text}
                                                        >
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faTrashCan}
                                                                    style={{ color: colors.danger }}
                                                                    onClick={(e) => handleDelete && handleDelete(e)}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}

                                                    <Tooltip text={t('pages.invoiceCard.dropdownItems.send').text}>
                                                        <Icon>
                                                            <FontAwesomeIcon
                                                                icon={faEnvelope}
                                                                style={{ color: colors.blue }}
                                                                onClick={(e) => handleSend && handleSend(e)}
                                                            />
                                                        </Icon>
                                                    </Tooltip>

                                                    {eInvoice && (
                                                        <Tooltip>
                                                            <Icon>
                                                                <FontAwesomeIcon
                                                                    icon={faE}
                                                                    style={{ color: colors.gray }}
                                                                />
                                                            </Icon>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </ActionWithoutDropdown>
                                    )}
                                </div>
                            </ActionWrapper>
                        ) : (
                            <></>
                        )}
                    </div>
                </InfoWrapper>
            </CardContent>
        </StyledInvoiceCard>
    );
};

export default EInvoiceCard;
