import { createGlobalStyle } from 'styled-components';
import colors from './colors';
import { MQ_BREAKPOINTS } from '../constants/breakpoints';

const GlobalStyle = createGlobalStyle`
    html {
        --white: ${colors.white};
        --black: ${colors.black};
        --purple: ${colors.purple};
        --gray: ${colors.gray};
        --light-gray: ${colors.lightGray};
        --blue:${colors.blue};
        --blue-gray:${colors.blueGray};
        --border-color: ${colors.borderColor};
        --danger: ${colors.danger};
        --green: ${colors.green};
        --light-green: ${colors.lightGreen};
        --light-blue: ${colors.lightBlue};
        --navbar-black: ${colors.navbarBlack};
        --navbar-gray: ${colors.navbarGray};
        --body-gray: ${colors.bodyGray};
        --blue: ${colors.blue};
        --mid-gray: ${colors.midGray};
        --orange: ${colors.orange};
        --light-orange: ${colors.lightOrange};
        --violet: ${colors.violet};
        --light-violet: ${colors.lightViolet};
        --turquoise: ${colors.turquoise};

        *,
        *::after,
        *::before {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
        }

        body {
            font-family: 'Open Sans', sans-serif;
            color: var(--black);

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 5px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: var(--purple);
            }
        }

        .body-public {
            height: 100vh;
        }

        #root {
            height:100%;
            width: -webkit-fill-available;
            .app {
                height:100%;
                .body {
                    height:100%;
                    &.private {
                        @media only screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
                            height: calc(100% - 88px);
                        }
                        &:has(.sidebar), &:has(.preview-modal) {
                           z-index:4;
                        }
                        &:has(.new-invoice), &:has(.edit-invoice) {
                            padding-bottom:0;
                        }
                        /* Scrollbar */
                        &::-webkit-scrollbar {
                            -webkit-appearance: none;
                            width: 7px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: transparent;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: var(--purple);
                        }
                    }

                }
            }
        }

        // For pages that are not full width
        .container {
            margin: 33px auto 20px auto;
            max-width: 1200px;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.laptopM}) {
                width: 100%;
            }
        }

        // Titles
        h1,
        h2,
        h3,
        h4,
        h5,p {
            margin: 0;
            font-weight: 400;
            line-height: 1;
        }

        h1 {
            font-size: 36px;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                font-size: 25px;
            }
        }

        h2 {
            font-size: 32px;
        }

        h3 {
            font-size: 26px;
        }

        h4 {
            font-size: 22px;
        }

        h5 {
            font-size: 18px;
            line-height: 34px;
        }

        h6 {
            font-size: 15px;
        }

        p {
            font-size: 16px;
        }

        span {
            font-size: 14px;
        }

        .cursor-pointer {
            cursor:pointer;
        }

        input {
            &:disabled {
                opacity: 0.6;
            }
        }

        a {
            text-decoration:none;
            color: var(--purple);
        }

        .Toastify__toast-container {
            width: 400px;
        }

        //Custom style for toast notifications
        .react-toast-notifications__container {
            .react-toast-notifications__toast {
                &.react-toast-notifications__toast--error {
                    background-color: var(--white);
                    color: var(--danger);
                    .react-toast-notifications__toast__icon-wrapper {
                        display:none;
                    }
                    .react-toast-notifications__toast__dismiss-icon{
                        path {
                            fill:var(--danger);
                        }
                    }
                }
                &.react-toast-notifications__toast--success {
                    background-color: var(--white);
                    color: var(--green);
                    .react-toast-notifications__toast__icon-wrapper {
                        display:none;
                    }
                    .react-toast-notifications__toast__dismiss-icon{
                        path {
                            fill:var(--green);
                        }
                    }
                }
            }
            .react-toast-notifications__toast--error .react-toast-notifications__toast__content,
            .react-toast-notifications__toast--success .react-toast-notifications__toast__content {
            font-size: 20px; /* Adjust the font size as needed */
            }
        }
    }
`;

export default GlobalStyle;
