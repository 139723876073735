import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FileUploader } from 'react-drag-drop-files';
import comunication from '../../../../../communication';
import useLanguageStore from '../../../../../store/language';
import useTranslations from '../../../../../hooks/useTranslation';
import { dragAndDropIcon } from '../../../../../assets/base64';
import Modal from '../../../../../components/modal';
import ConfirmModal from '../../../../../components/confirmModal';
import moment from 'moment';
import SelectComponent from '../../../../../components/select';
import useAgencyStore from '../../../../../store/agency';
import ErrorModal from '../../../../../components/errorModal';
import { ObjectKeys } from '../../../../../types/objectKeys';
import Pagination from '../../../../../components/pagination';
import Skeleton from './skeleton';
import { MQ_BREAKPOINTS } from '../../../../../constants/breakpoints';
import DragAndDropItem from '../../../../../components/dragAndDrop/dragAndDropItem';

interface Props {
    className?: string;
    fileTypes?: Array<string>;
    type?: string;
    description?: string;
    multiple?: boolean;
    searchYear?: string;
    taxCurrentYear?: SelectOption;
    preventUpload?: boolean;
    updateSearchYear?: Function;
}

type SelectOption = {
    value: string;
    label: string;
};

interface InstructionProps {
    agency?: Object;
    created_at: string;
    file_name: string;
    hashed_name: string;
    id: string;
    object: string;
    type: string;
    updated_at: string;
    user: Object;
    user_id: string;
}

const DragAndDrop: FunctionComponent<Props> = ({
    className = 'col-6 offset-3',
    fileTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png', 'gif'],
    type,
    description,
    multiple,
    searchYear,
    taxCurrentYear,
    preventUpload,
    updateSearchYear,
}) => {
    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: `year:${searchYear}`,
        searchFields: 'year:=',
        searchJoin: 'and',
        orderBy: 'created_at',
        sortedBy: 'desc',
    });
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [files, setFiles] = useState<Array<InstructionProps>>();
    const [meta, setMeta] = useState<ObjectKeys>({});
    const [modalActive, setModalActive] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<string>('');
    const [yearsForTaxSolution, setYearsForTaxSolution] = useState<SelectOption[]>([]);
    const [selectedYear, setSelectedYear] = useState<string>(moment().year().toString());
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const [wrongTypeModal, setWrongTypeModal] = useState<boolean>(false);
    const [overSizeModal, setOverSizeModal] = useState<boolean>(false);
    const token = localStorage.getItem('access_token');
    const agency = useAgencyStore((props) => props.agency);
    const user_id = agency.user.data.id;

    const getDocuments = async (): Promise<void> => {
        setLoaderVisible(true);
        comunication
            .getAllEcotaxSolution(params)
            .then((response: ObjectKeys) => {
                setLoaderVisible(false);
                setMeta(response.data.meta);
                const res = response.data.data;
                setFiles(res);
            })
            .catch((error: any) => {
                setLoaderVisible(false);
                console.error(error);
            });
    };

    const generateYears = (): void => {
        const registartionYear = moment(agency.date_of_registration).year();
        const currentYear = moment().year();
        for (let i = currentYear; i >= registartionYear; i--) {
            setYearsForTaxSolution((prevState) => [...prevState, { label: i.toString(), value: i.toString() }]);
        }
    };

    const handleChange = async (file: Blob[]): Promise<void> => {
        setLoaderVisible(true);
        if (file[0].size > 5242880) {
            setLoaderVisible(false);
            setOverSizeModal(true);
        } else {
            comunication
                .uploadEcotaxSolutions(user_id, file[0], selectedYear)
                .then((uploaded_solution: ObjectKeys) => {
                    updateSearchYear && updateSearchYear(uploaded_solution.data.data.year);
                    getDocuments();
                })
                .catch((error: any) => {
                    console.error(error);
                });
        }
        setLoaderVisible(false);
    };

    const handleDelete = async (id: string): Promise<void> => {
        setLoaderVisible(true);
        comunication
            .deleteEcotaxSolution(id)
            .then((response: ObjectKeys) => {
                if (response.status === 204) {
                    setLoaderVisible(false);
                    getDocuments();
                }
            })
            .catch((error: any) => {
                setLoaderVisible(false);
                console.error(error);
            });
    };

    const handleDownload = async (id: string): Promise<void> => {
        if (!token) return;
        comunication.downloadEcotaxSolution(id, token);
    };

    useEffect(() => {
        getDocuments();
    }, [params.page]);

    useEffect(() => {
        if (agency) {
            generateYears();
        }
    }, []);

    useEffect(() => {
        setSelectedYear(taxCurrentYear ? taxCurrentYear?.value : yearsForTaxSolution[0]?.value);
    }, [taxCurrentYear?.value]);

    useEffect(() => {
        if (files && files.length === 0 && meta && meta.pagination && meta.pagination.current_page > 1) {
            setParams({ ...params, page: meta.pagination.current_page - 1 });
        }
    }, [files]);

    return (
        <>
            <CustomFileUploader className={`uploader-wrapper ${className}`}>
                {modalActive && (
                    <Modal modalVisible={modalActive} closeModal={() => setModalActive(!modalActive)}>
                        <ConfirmModal
                            action={async () => {
                                setModalActive(false);
                                await handleDelete(selectedId);
                            }}
                            close={() => setModalActive(false)}
                            active={modalActive}
                            message={
                                type === 'tax'
                                    ? t('pages.agency.taxSolutions.delete').text
                                    : t('pages.agency.paymentInstructions.delete').text
                            }
                        />
                    </Modal>
                )}
                {wrongTypeModal && (
                    <Modal modalVisible={wrongTypeModal} closeModal={() => setWrongTypeModal(!wrongTypeModal)}>
                        <ErrorModal
                            t={t}
                            errorMessage={
                                type === 'tax'
                                    ? t('pages.agency.taxSolutions.wrongType').text
                                    : t('pages.agency.paymentInstructions.wrongType').text
                            }
                            setOpenModal={setWrongTypeModal}
                        />
                    </Modal>
                )}
                {overSizeModal && (
                    <Modal modalVisible={overSizeModal} closeModal={() => setOverSizeModal(!overSizeModal)}>
                        <ErrorModal
                            t={t}
                            errorMessage={t('pages.error.overSize').text}
                            setOpenModal={setOverSizeModal}
                        />
                    </Modal>
                )}
                <div className="uploaded-files">
                    {loaderVisible ? (
                        <Skeleton />
                    ) : (
                        <>
                            {files &&
                                files.length > 0 &&
                                files.map((file, index) => (
                                    <DragAndDropItem
                                        key={index}
                                        name={file.file_name}
                                        date={file.created_at}
                                        id={file.id}
                                        handleDelete={(id) => {
                                            setSelectedId(id);
                                            setModalActive(true);
                                        }}
                                        handleDownload={(id) => handleDownload(id)}
                                        label={
                                            type === 'tax'
                                                ? t('pages.agency.taxSolutions.uploaded').text
                                                : t('pages.agency.paymentInstructions.uploaded').text
                                        }
                                    />
                                ))}
                        </>
                    )}
                </div>
                {files && meta.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={meta?.pagination?.total_pages}
                        onPageChange={(e: ObjectKeys) => setParams({ ...params, page: e.selected + 1 })}
                        nextLabel={`${meta?.pagination?.current_page === meta?.pagination?.total_pages ? '' : '>'}`}
                        previousLabel={`${meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={meta?.pagination?.current_page - 1}
                    />
                )}
                <div className="description">{t('pages.eco_tax_transfer.choose_year_for_uploading').text}</div>
                {type === 'tax' && (
                    <div className={'year-container'}>
                        {t('pages.agency.taxSolutions.year').text}{' '}
                        <SelectComponent
                            optionList={yearsForTaxSolution}
                            defaultSelectedOption={taxCurrentYear ? (taxCurrentYear as any) : yearsForTaxSolution[0]}
                            handleSelectedValue={(value: any) => {
                                setSelectedYear(value);
                            }}
                        />
                    </div>
                )}
                {!preventUpload && (
                    <FileUploader
                        multiple={multiple ? multiple : false}
                        handleChange={handleChange}
                        readFileAsBinary={true}
                        name="files"
                        types={fileTypes}
                        onTypeError={() => {
                            setWrongTypeModal(true);
                        }}
                    >
                        <div className={'uploader-wrapper'}>
                            <img src={dragAndDropIcon} className="upload-icon" />
                            <div className="upload-text">
                                {type === 'tax'
                                    ? t('pages.agency.paymentInstructions.addDocument').text
                                    : t('pages.agency.taxSolutions.addDocument').text}
                            </div>
                        </div>
                    </FileUploader>
                )}
            </CustomFileUploader>
        </>
    );
};

const CustomFileUploader = styled.article`
    display: inline-block;
    vertical-align: top;
    align-self: flex-end;
    border-radius: 10px;
    box-shadow: rgb(221, 221, 221) 0px 0px 5px 5px;
    margin-bottom: 30px;
    padding: 30px;
    color: rgb(146, 165, 172);
    width: 100%;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 15px;
    }

    .description {
        text-align: center;
        color: #92a5ac;
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 30px;
        margin-top: 30px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            margin-top: 15px;
            margin-bottom: 15px;
            line-height: 20px;
            text-align: left;
            font-size: 14px;
        }
    }
    .uploader-wrapper {
        width: 100%;
        cursor: pointer;
        position: relative;
        min-height: 220px;
        border: 3px dashed #e7e7e7;
        color: #92a5ac;
        border-radius: 10px;
        padding: 50px;
        vertical-align: middle;
        text-align: center;
        :hover {
            opacity: 0.7;
        }
    }
    .upload-icon {
        margin-bottom: 20px;
        height: 100px;
    }
    .upload-text {
        text-align: center;
        padding-top: 20px !important;
        border-top: 1px solid #e1e3eb;
    }

    .year-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: end;
        margin-bottom: 20px;
        gap: 1rem;
        .select-wrapper {
            width: fit-content !important;
        }
    }
`;

export default DragAndDrop;
