export const FOREIGN_BANK_ACCOUNTS_CURRENCIES = ['EUR', 'USD', 'CHF', 'GBP', 'AUD', 'CAD', 'NOK', 'RUB', 'CNY'];

export const MAX_INSTRUCTION_FILE_SIZE = 5242880;

export const INSTRUCTIONS_ALLOWED_FILE_TYPES = [
    'application/pdf', // PDF files
    'application/msword', // DOC files
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX files
    'application/vnd.ms-excel', // XLS files
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX files
    'image/jpeg', // JPEG files
    'image/jpg', // JPG files
    'image/png', // PNG files
    'image/gif', // GIF files
];

export const NAVBAR_WIDTH = 248;
