import { FunctionComponent, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faList } from '@fortawesome/free-solid-svg-icons';
import communication from '../../../communication';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import useProfileStore from '../../../store/profile';
import Button, { ButtonVariant } from '../../../components/button';
import { ObjectKeys } from '../../../types/objectKeys';
import { SendDocumentFormData } from '../../../types/communication/document';
import { formatParams } from '../../../functions/formatParams';
import { getAccessToken } from '../../../functions/auth';
import InvoiceCard, { InvoiceCardComponentType } from '../../../components/invoiceCard';
import NoItems from '../../../components/noItems';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';
import Sidebar from '../../../components/sideModal';
import Pagination from '../../../components/pagination';
import Dropdown from '../../../components/dropdown';
import AccountExpired from '../../../components/accountExpired';
import Filters from './filters';
import CancelModal from '../common/cancelModal';
import EAdvanceModal from '../common/eInvoiceModal';
import DeleteModal from '../common/deleteModal';
import SendDocumentModal from './sendModal';
import ErrorModal from '../../../components/errorModal';
import NoEInvoiceApiModal from '../common/noEinvoiceApiModal';
import Success from '../../../components/successModal';
import useInvoicesStore from '../../../store/invoices';
import useAgencyStore from '../../../store/agency';
import useParamsStore from '../../../store/params';
import InvoiceSkeleton from '../common/skeleton/invoiceCardSkeleton';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import FilterTrack from '../common/filterTrack';
import useInvoiceParamsStore from '../../../store/invoiceParams';

const AdvanceInvoices: FunctionComponent = () => {
    const page = useParamsStore.getState().a_page;
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const { profile } = useProfileStore();
    const { advanceInvoiceData } = useInvoicesStore();
    const { agency } = useAgencyStore();

    const token = getAccessToken();
    const isAccountActive = profile?.active === 1 ? true : false;

    const [searchValue, setSearchValue] = useState('');
    const [successEadvance, setSuccessEadvance] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openEAvanceModal, setOpenEAvanceModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [addInvoiceDropdown, setAddInvoiceDropdown] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [noEInvoiceApiModal, setNoEInvoiceApiModal] = useState(false);
    const [invoiceToCancel, setInvoiceToCancel] = useState<ObjectKeys>({});
    const [invoiceToEinvoice, setInvoiceToEinvoice] = useState<ObjectKeys>({});

    const [clientEmail, setClientEmail] = useState('');
    const [display, setDisplay] = useState('list');
    const [clickedInvoice, setClickedInvoice] = useState('');
    const [success, setSuccess] = useState(false);

    const [showSkeleton, setShowSkeleton] = useState(false);

    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: {},
        searchFields: {},
        searchJoin: 'and',
        orderBy: '',
        sortedBy: '',
    });

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const { handleClickOutside } = useOutsideClick(ref, setAddInvoiceDropdown);

    const formattedParams = useMemo(() => formatParams(params.search), [params]);

    useEffect(() => {
        useParamsStore.setState({ a_page: params.page });
        getInvoices();
    }, [formattedParams, params.limit, page, params.orderBy, params.sortedBy]);

    useEffect(
        () => () => {
            if (page > 1) {
                useInvoicesStore.setState({ advanceInvoiceData: [] });
                setParams({ ...params, page: 1 });
                useParamsStore.setState({ a_page: 1 });
            }
        },
        [],
    );

    const getInvoices = (): Promise<boolean> => {
        setShowSkeleton(true);
        return new Promise<boolean>((resolve) => {
            const finalParams = {
                ...params,
                page: page ? page : 1,
                search: 'is_prepaid:1;' + formattedParams,
                searchFields: 'is_prepaid:=;' + formattedParams,
            };
            useInvoiceParamsStore.setState({ advances_params: finalParams });
            communication
                .getInvoices(finalParams)
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        setShowSkeleton(false);
                        useInvoicesStore.setState({ advanceInvoiceData: res.data });
                        resolve(true);
                    }
                })
                .catch(() => {
                    setShowSkeleton(false);
                    useInvoicesStore.setState({ advanceInvoiceData: [] });
                    resolve(false);
                });
        });
    };

    //Advance Invoice Change
    const handleChange = (invoiceId: string): void => {
        navigate(`/advance-invoices/edit/${invoiceId}`);
    };

    //Advance Invoice Copy
    const handleCopy = (invoiceId: string): void => {
        navigate(`/advance-invoices/copy/${invoiceId}`);
    };
    //Refresh eInvoice
    const handleRefreshEInvoice = async (eInvoiceId: string): Promise<void> => {
        setLoaderVisible(true);
        setClickedInvoice(eInvoiceId);

        try {
            const res = await communication.refreshEInvoice({ id: eInvoiceId });

            if (res.status === 200) {
                setLoaderVisible(false);
                getInvoices().then((resp: boolean) => {
                    if (resp) {
                        setSuccess(true);
                    }
                });
            } else {
                console.error('Unexpected response status code:', res.status);
                setLoaderVisible(false);
            }
        } catch (error: any) {
            setLoaderVisible(false);
            setErrorMessage(error.response.data.message);
            setErrorModal(true);
        }
    };

    //AInvoice Delete Modal
    const handleDeleteModal = (invoice: ObjectKeys): void => {
        setGlobalModal(
            <DeleteModal
                t={t}
                clickedInvoice={invoice}
                closeGlobalModal={closeGlobalModal}
                onDeleteInvoice={() => getInvoices()}
            />,
        );
    };

    //AInvoice Cancel Modal
    const handleCancelModal = (invoice: ObjectKeys): void => {
        setClickedInvoice(invoice.id);
        setInvoiceToCancel(invoice);
        setOpenCancelModal(true);
    };

    //AInvoice Download
    const handleDownloadInvoice = (invoiceId: string, invoice: ObjectKeys): void => {
        communication.downloadInvoice(invoiceId, token, invoice.invoice_number, invoice.currency !== 'RSD');
    };

    const handleOpenEInvoiceModal = (invoiceId: string): void => {
        setOpenEAvanceModal(true);
        setClickedInvoice(invoiceId);
    };

    const handleEAdvanceModal = async (invoice: ObjectKeys): Promise<void> => {
        setInvoiceToEinvoice(invoice);
        if (agency.e_invoice_api_key) handleOpenEInvoiceModal(invoice.id);
        else setNoEInvoiceApiModal(true);
    };

    const handleClickedInvoice = (invoice: ObjectKeys): void => {
        setClickedInvoice(invoice.id);
        setInvoiceToCancel(invoice);
    };

    const handleSendModal = (invoiceId: string, invoiceClientEmail: string): void => {
        setSendModal(true);
        setClickedInvoice(invoiceId);
        setClientEmail(invoiceClientEmail);
    };
    const handleSendInvoice = (): void => {
        setClickedInvoice('');
    };
    const onLoad = (loading: boolean): void => {
        setLoaderVisible(loading);
    };

    //Add new advance invoice
    const handleAddInvoice = (invoiceType: string): void => {
        navigate(`/advance-invoices/add/${invoiceType}`);
    };

    return (
        <>
            <PageWrapper>
                {errorModal && (
                    <Modal modalVisible={errorModal} closeModal={() => setErrorModal(false)}>
                        <ErrorModal t={t} setOpenModal={setErrorModal} errorMessage={errorMessage} />
                    </Modal>
                )}
                {noEInvoiceApiModal && (
                    <Modal
                        modalVisible={true}
                        closeModal={() => {
                            setNoEInvoiceApiModal(false);
                        }}
                    >
                        <NoEInvoiceApiModal
                            saveChanges={() => navigate('/settings/e-invoice')}
                            close={() => {
                                setNoEInvoiceApiModal(false);
                            }}
                            message={t('pages.invoices.noEInvoiceKeyModal.title').text}
                        />
                    </Modal>
                )}
                {sendModal && (
                    <Sidebar close={() => setSendModal(false)}>
                        <SendDocumentModal
                            close={() => setSendModal(false)}
                            setFormData={setSendData}
                            formData={sendData}
                            t={t}
                            id={clickedInvoice}
                            success={() => {
                                handleSendInvoice();
                            }}
                            loading={(e: boolean) => onLoad(e)}
                            clientEmail={clientEmail}
                            errorMessage={(mess: string) => {
                                setErrorMessage(mess);
                                setErrorModal(true);
                            }}
                            modalVisible={sendModal}
                        />
                    </Sidebar>
                )}
                {openCancelModal ? (
                    <Modal modalVisible={openCancelModal} closeModal={() => setOpenCancelModal(false)}>
                        <CancelModal
                            t={t}
                            setOpenCancelModal={setOpenCancelModal}
                            clickedInvoice={invoiceToCancel}
                            onCancelInvoice={getInvoices}
                        />
                    </Modal>
                ) : (
                    <></>
                )}
                {openEAvanceModal ? (
                    <Modal modalVisible={openEAvanceModal} closeModal={() => setOpenEAvanceModal(false)}>
                        <EAdvanceModal
                            t={t}
                            setOpenEInvoiceModal={setOpenEAvanceModal}
                            clickedInvoice={invoiceToEinvoice}
                            onEinvoiceSent={() => {
                                setSuccessEadvance(true);
                                getInvoices();
                            }}
                        />
                    </Modal>
                ) : (
                    <></>
                )}
                {successEadvance && (
                    <Modal modalVisible={true} closeModal={() => setSuccessEadvance(false)}>
                        <Success
                            close={() => setSuccessEadvance(false)}
                            message={t('pages.eInvoices.sentEadvance').text}
                        />
                    </Modal>
                )}
                {success && (
                    <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                        <Success close={() => setSuccess(false)} message={t('pages.eInvoices.refreshSuccess').text} />
                    </Modal>
                )}
                {loaderVisible && <Loader />}
                <div className="advance-invoices page">
                    <Header>
                        <div>
                            <h1>{t('pages.advanceInvoices.title').text}</h1>
                            <AddButtonContainer>
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={colors.purple}
                                    icon={true}
                                    size={'auto'}
                                    onClick={() => {
                                        if (isAccountActive) {
                                            setAddInvoiceDropdown(!addInvoiceDropdown);
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                                    {t('pages.advanceInvoices.newInvoice').text}
                                </Button>
                                {addInvoiceDropdown && (
                                    <DropdownContainer ref={ref} onClick={handleClickOutside}>
                                        <Dropdown
                                            arrowRight="4px"
                                            itemList={[
                                                {
                                                    label: t('pages.advanceInvoices.addInvoiceDropdown.aInvoice').text,
                                                    handler: () => handleAddInvoice('domestic'),
                                                },
                                                {
                                                    label: t('pages.advanceInvoices.addInvoiceDropdown.foreign').text,
                                                    handler: () => handleAddInvoice('foreign'),
                                                },
                                            ]}
                                        />
                                    </DropdownContainer>
                                )}
                            </AddButtonContainer>
                        </div>
                    </Header>
                    {isAccountActive ? <></> : <AccountExpired />}
                    <Filters
                        setParams={setParams}
                        params={params}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setDisplay={setDisplay}
                    />
                    <FilterTrack
                        onChevronClick={(name: string, order: string) => {
                            setParams({ ...params, orderBy: name, sortedBy: order });
                        }}
                        t={t}
                    />
                    {showSkeleton ? (
                        Array.from({ length: 10 }).map((_, index) => <InvoiceSkeleton key={index} />)
                    ) : (
                        <>
                            <InvoicesList className={`display-${display}`}>
                                {advanceInvoiceData?.data?.length > 0 ? (
                                    advanceInvoiceData?.data?.map((invoicePrev: ObjectKeys, index: number) => {
                                        const invoice: ObjectKeys = {
                                            ...invoicePrev,
                                            services: invoicePrev.original_data.services,
                                            client: { data: invoicePrev.original_data.client },
                                        };
                                        return (
                                            <div key={invoice.id} className={`${display}`}>
                                                <InvoiceCard
                                                    type={InvoiceCardComponentType.AdvanceInvoice}
                                                    dropdownDirection={index > 2 ? 'up' : 'down'}
                                                    cardData={invoice}
                                                    cardDisplay={display}
                                                    handleChange={() => handleChange(invoice.id)}
                                                    handleCopy={() => handleCopy(invoice.id)}
                                                    handleDelete={() => handleDeleteModal(invoice)}
                                                    handleCancel={() => handleCancelModal(invoice)}
                                                    handleDownload={() => handleDownloadInvoice(invoice?.id, invoice)}
                                                    handleEInvoice={() => handleEAdvanceModal(invoice)}
                                                    handleSend={() =>
                                                        handleSendModal(
                                                            invoice.id,
                                                            invoice.client.data.email ? invoice.client.data.email : '',
                                                        )
                                                    }
                                                    handleClick={() => handleClickedInvoice(invoice)}
                                                    eInvoice={invoice.is_einvoice}
                                                    handleRefresh={() => {
                                                        handleRefreshEInvoice(invoice.id);
                                                    }}
                                                />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <NoItems text={t('pages.invoices.noItems.descriptionPrepaid').text} />
                                )}
                            </InvoicesList>
                            {advanceInvoiceData?.data?.length > 0 && (
                                <Pagination
                                    pageCount={advanceInvoiceData.meta?.pagination?.total_pages}
                                    onPageChange={(e: ObjectKeys) => {
                                        setParams({ ...params, page: e.selected + 1 });
                                        useParamsStore.setState({ a_page: e.selected + 1 });
                                    }}
                                    nextLabel={`${
                                        advanceInvoiceData.meta?.pagination?.current_page ===
                                        advanceInvoiceData.meta?.pagination?.total_pages
                                            ? ''
                                            : '>'
                                    }`}
                                    previousLabel={`${
                                        advanceInvoiceData.meta?.pagination?.current_page === 1 ? '' : '<'
                                    }`}
                                    breakLabel="..."
                                    initialPage={advanceInvoiceData.meta?.pagination?.current_page - 1}
                                />
                            )}
                        </>
                    )}
                </div>
            </PageWrapper>
        </>
    );
};
export default AdvanceInvoices;

const PageWrapper = styled.div`
    min-height: 100vh; /* Default min-height */

    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileXS}) {
        min-height: 1400px;
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileS}) and (max-width: ${MQ_BREAKPOINTS.mobileM}) {
        min-height: 1300px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileM}) and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        min-height: 1300px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileL}) and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        min-height: 1200px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        min-height: 1100px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptop}) and (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        min-height: 850px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        min-height: 950px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopL}) {
        min-height: 1000px;
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    > div {
        display: flex;
        align-item: center;
        flex-wrap: wrap;
        gap: 1rem;
        button {
            margin-left: 35px;
            height: 35px;
            font-size: 12px;
            line-height: initial;
        }
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
        > div {
            width: 100%;
            justify-content: space-between;
            button {
                margin-left: 0;
            }
        }
    }
`;
const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        /* grid-template-columns: 1fr 1fr 1fr; */
        @media screen and (max-width: 1023px) {
            grid-template-columns: 1fr;
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.laptop}) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
`;

const AddButtonContainer = styled.div`
    position: relative;
`;

const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 55px;

    p {
        margin-left: 0;
    }
    .dropdown-container {
        height: auto;
        overflow-y: auto;
    }
`;
export const NoData = styled.div`
    flex: 1;
    text-align: center;
    margin: 60px 0;
    flex-wrap: wrap;
    align-items: center;
    margin: 30px 0;
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.4;
    }
    a {
        font-weight: bold;
    }
`;
