import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../components/button';
import colors from '../../global/colors';
import { ObjectKeys } from '@/types/objectKeys';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';

interface TransferOrderPreviewModalProps {
    image: string;
    onClose: Function;
    transfer: ObjectKeys;
    isQrCode: boolean;
    downloadTax: Function;
}

const TransferOrderPreviewModal: FunctionComponent<TransferOrderPreviewModalProps> = ({
    image,
    onClose,
    transfer,
    isQrCode,
    downloadTax,
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    return (
        <Content>
            <Header>
                <h1>{t('pages.transfers.preview_title').text}</h1>
                <button className={'close_button'} onClick={() => onClose()} />
            </Header>
            <Body>
                <div className={'left-side'}>
                    <div className={'field'}>
                        <span className={'label'}>{t('pages.transfers.orderer').text}</span>
                        <span className={'input'}>{transfer.orderer}</span>
                    </div>
                    <div className={'field'}>
                        <span className={'label'}>{t('pages.transfers.purpose_of_payment').text}</span>
                        <span className={'input'}>{transfer.payment_slip_heading}</span>
                    </div>
                    <div className={'field'}>
                        <span className={'label'}>{t('pages.transfers.recipient').text}</span>
                        <span className={'input'}>{transfer.recipient}</span>
                    </div>
                </div>
                <div className={'right-side'}>
                    <div className={'field m'}>
                        <span className={'label'}>{t('pages.transfers.payment_code').text}</span>
                        <span className={'input'}>{transfer.payment_code}</span>
                    </div>
                    <div className={'field m'}>
                        <span className={'label'}>{t('pages.transfers.currency').text}</span>
                        <span className={'input'}>{transfer.currency}</span>
                    </div>
                    <div className={'field l'}>
                        <span className={'label'}>{t('pages.transfers.amount').text}</span>
                        <span className={'input'}>{transfer.total_amount.replace(/\./g, ',')}</span>
                    </div>
                    <div className={'field'}>
                        <span className={'label'}>{t('pages.transfers.debtor_account').text}</span>
                        <span className={'input'}>{transfer.debtor_account}</span>
                    </div>
                    <div className={'field'}>
                        <span className={'label'}>{t('pages.transfers.model_and_call_number_debtor').text}</span>
                        <span className={'input s'}> </span>
                        <span className={'input xl'}> </span>
                    </div>
                    <div className={'field'}>
                        <span className={'label'}>{t('pages.transfers.recepient_bank_account').text}</span>
                        <span className={'input'}>{transfer.recipient_account}</span>
                    </div>
                    <div className={'field'}>
                        <span className={'label'}>{t('pages.transfers.model_and_call_number_recipient').text}</span>
                        <span className={'input s'}>{transfer.model_number_recipient || '00'}</span>
                        <span className={'input xl'}>{transfer.call_number_recipient || '000000000'}</span>
                    </div>
                </div>
                <div className={'footer'}>
                    <div className={'signature'}>
                        <span>{t('pages.transfers.transfer_order_signature').text}</span>
                    </div>
                    <div className={'signature'}>
                        <span>{t('pages.transfers.transfer_order_date_time').text}</span>
                    </div>
                    <div className={'signature'}>
                        <span>{t('pages.transfers.transfer_execution').text}</span>
                    </div>
                </div>
                <div className={'qr-code'}>
                    <Button variant={ButtonVariant.solid} color={colors.purple} onClick={() => downloadTax(transfer)}>
                        {t('pages.transfers.print').text}
                    </Button>
                    {isQrCode && <img src={`data:image/png;base64,${image}`} />}
                </div>
            </Body>
        </Content>
    );
};

const Content = styled.div`
    overflow: auto;
`;

const Header = styled.div`
    position: relative;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding: 2px 15px 20px;
    h1 {
        font-size: 24px;
        text-transform: uppercase;
        color: #4c595f;
        font-weight: 400;
    }
    .close_button {
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAfCAYAAACGVs+MAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAs1JREFUeNq0l81LVFEYxu+cJkkTG5hFIOmmFi6iRS6EKIoGYygbK/qQiMQ25UJ3NdBfENmmJCJa9EH0JSXaB1NiJBLVIok2FbQIowIhsJJRxsqeF56By3DeM/c6txd+szjv13Mu9555T+zlmw8Jz/POg13gGzgL+r3/Yz0gC5aDB6A3jp+LYD8DVoNzoAGciLj5KTYv2iFQZfDTZgk+TiGxCBrHWCtr8WVEwCfH47pQoYgYa/Qo/s8i4CT4owQcBVfAkkU0l5zLrGEz6ZkVAUPgIJhXAg+D6yAeonmcOZ2Kf549Bw0X7oC9oKAkdIDb8tIEaF7F2A7FLz32sadnfI5h0A5mlcQ94B5Y5mguvruMtdksewwVF0xJQA7sBHmlwA4KrbH4auhrU3LzrJ3zLxpL4ChIg59KoVbwCNT61mq51qrk/GLN0VKHURLGwTYwrfg3g8dgBclxzWbTFDauva2avQIp8AQkLf4Nvh01KzW+cyMTWhNT5o2WxK1gSvE3O5pPMXfC1cAE+Kze8vF+DXEOSOwW5nqVChB7TxGTAWInGfsuSGETYlcfeSqWs07GelELWAOuBYi7ythIBTSBMdAYILaRsU1RCVgLnoH6EE+rnjnrKhWwnoVWKv7XxGaS85Q1FiWghQdNUvG/4EElPFdikqzRElbARp6ACcU/xhPuB0lzzWYJ1toUVECKZ3udUnAEbAczvrUZro0oOXWsmSonQHZyn2OzzeQfL6P8Xefpe6jk1rB2WhOQ4aBQrRQYBLvBnOO9mfMNLjarZo/2UgFyLxhwjFy3GFMI8AlKzAHmaCPbQPEuEqeaG47JV06/I47J2Wa/efEoKMf3UvYsyBM442h+CXSFbO4fu7t489LG9tMiYJUS0M+Z/m8FFxPJ7XbcNRsM38xS65OLI1iI4Gq2wFp9Ft+w4S5v8jP6QsVRX0w91uxmjzx7HvsnwADMMpd5tpG+eAAAAABJRU5ErkJggg==)
            no-repeat;
        background-size: 16px 16px;
        width: 16px;
        height: 16px;
        text-indent: -9999px;
        border: 0;
        &:hover {
            cursor: pointer;
        }
    }
`;

const Body = styled.div`
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    .left-side {
        -ms-flex: 1 1 50%;
        flex: 1 1 50%;
        .input {
            display: block;
            font-size: 12px;
            font-weight: 700;
            min-height: 88px;
            padding: 12px;
            border: 1px solid #adbcc1;
            line-height: 25px;
        }
    }
    .right-side {
        -ms-flex: 1 1 50%;
        flex: 1 1 50%;

        .input {
            display: block;
            font-size: 12px;
            font-weight: 700;
            min-height: auto;
            padding: 6px 12px;
            border: 1px solid #adbcc1;
            line-height: 25px;
            min-height: 39px;
        }
    }
    .footer {
        margin: 10px 10px 20px;
        width: 100%;
        .signature {
            border: 0;
            border-top: 1px solid #000;
            width: 170px;
            margin-top: 40px;
            display: block;
            margin-right: 40px;
            float: left;
        }
    }
    .qr-code {
        display: -ms-flexbox;
        display: flex;
        flex: 1;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        img {
            width: 200px;
        }
    }
    .field {
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        margin: 10px 10px 2px 10px;
        overflow: hidden;

        .label {
            font-size: 13px;
            color: #92a5ac;
            display: block;
            margin-bottom: 5px;
        }
    }

    .s {
        float: left;
        width: 70px;
    }
    .m {
        display: inline-table;
        width: calc(29% - 10px);
        margin-right: 0;
    }
    .l {
        display: inline-table;
        width: calc(38% - 10px);
        margin-right: 0;
    }

    .xl {
        float: left;
        margin-left: 10px;
        width: calc(100% - 80px);
    }
`;
export default TransferOrderPreviewModal;
