import Button, { ButtonVariant } from '../../../../components/button';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { FunctionComponent, useEffect, useRef, useState } from 'react';

import { BankAccount } from '../../../../communication/bankAccounts/types';
import ConfirmModal from '../../../../components/confirmModal';
import ForeignBankAccountNumber from './ForeignBankAccountNumber';
import Loader from '../../../../components/loader';
import Modal from '../../../../components/modal';
import { ObjectKeys } from '../../../../types/objectKeys';
import communication from '../../../../communication';
import { isStatusSuccess } from '../../../../communication/handlers/handleRequest';
import styled from 'styled-components';
import useAllBanksQuery from '../../../../react-query/useAllBanksQuery';
import useBankAccountsQuery from '../../../../react-query/useBankAccountsQuery';
import useForeignBankAccountSchema from './useForeignBankAccountSchema';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FOREIGN_BANK_ACCOUNTS_CURRENCIES, INSTRUCTIONS_ALLOWED_FILE_TYPES } from '../../../../constants/index';
import { toast } from 'react-toastify';
import ErrorModal from '../../../../components/errorModal';
import { useBlocker } from 'react-router-dom';
import { DevTool } from '@hookform/devtools';
import { isAxiosError } from 'axios';
import useAgencyStore from '../../../../store/agency';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';

interface Props {
    agency: ObjectKeys;
}

const ForeignAccounts: FunctionComponent<Props> = ({ agency }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { ForeignBankAccountSchema } = useForeignBankAccountSchema();
    type NewForeignAccountSchemaType = z.infer<typeof ForeignBankAccountSchema>;

    const methods = useForm<{
        accounts: BankAccount[];
        newAccount: NewForeignAccountSchemaType;
    }>({
        defaultValues: {
            accounts: [],
            newAccount: [],
        },
        resolver: zodResolver(
            z.object({
                accounts: ForeignBankAccountSchema,
                newAccount: ForeignBankAccountSchema,
            }),
        ),
        mode: 'onChange',
    });

    const { append: appendNewAccount } = useFieldArray({
        control: methods.control,
        name: 'newAccount',
    });

    const { data: allBanks, isLoading } = useAllBanksQuery();

    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return (
            methods.formState.isDirty &&
            (currentLocation.pathname !== nextLocation.pathname || currentLocation.search !== nextLocation.search)
        );
    });

    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const [initialData, setInitialData] = useState<BankAccount[]>([]);

    const itemsPerPage = 10;

    const { data: allBankAccounts, refetch, status, isFetching } = useBankAccountsQuery(1, itemsPerPage, 'foreign');

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isDeletingAccountType, setIsDeletingAccountType] = useState<'existing' | 'new' | null>(null);

    const [deletingId, setDeletingId] = useState<string | null>(null);

    const currencyOptions =
        FOREIGN_BANK_ACCOUNTS_CURRENCIES?.map((currency) => {
            return { label: currency, value: currency };
        }) || [];

    const watchedExistingAccounts = methods.watch('accounts');
    const watchedNewAccount = methods.watch('newAccount');
    const isAbleToSave = Object.values(methods.formState.errors).length === 0;

    const uploadInstructionRef = useRef<HTMLInputElement | null>(null);

    const [uploadingInstructionCurrency, setUploadingInstructionCurrency] = useState<string | null>(null);
    const [uploadingInstructionBankId, setUploadingInstructionBankId] = useState<string | null>(null);

    const [isOverSizeModalOpen, setIsOverSizeModalOpen] = useState<boolean>(false);
    const [isWrongFormatModalOpen, setIsWrongFormatModalOpen] = useState<boolean>(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const addBankAccount = (): void => {
        appendNewAccount({
            currency: '',
            iban: '',
            swift: '',
            bank_id: '',
        });
    };

    const handleDataChange = (): void => {
        setIsLoaderVisible(true);
        const accountsData = allBankAccounts?.data || [];
        if (status === 'success') {
            setInitialData(accountsData);
            methods.setValue('accounts', [], { shouldDirty: false });
            methods.reset({ accounts: accountsData, newAccount: [] });
            setIsLoaderVisible(false);
        }
        setIsLoaderVisible(false);
    };

    const saveNewAccount = async (): Promise<void> => {
        if (watchedNewAccount.length > 0 && isAbleToSave) {
            const newAccountData = {
                ...watchedNewAccount[0],
                agency_id: agency.id,
                type: 'foreign',
            };
            try {
                const response = await communication.createBankAccount(newAccountData);

                if (isStatusSuccess(response.status)) {
                    methods.setValue('newAccount', []);
                    communication.getAgency().then((res: ObjectKeys) => {
                        useAgencyStore.setState({
                            agency: res.data.data,
                        });
                    });
                    toast.success(t('pages.agency.bankAccounts.messages.add_account_success').text);
                } else {
                    throw Error('Error saving domestic accounts:');
                }
            } catch (error) {
                if (isAxiosError(error)) {
                    if (error.response?.data.message) {
                        setErrorMessage(error.response.data.message);
                        setIsErrorModalOpen(true);
                    }
                    return;
                }
                toast.error(t('pages.agency.bankAccounts.messages.add_account_error').text);
            }
        }
    };

    const saveExistingAccounts = async (updatedAccounts: BankAccount[]): Promise<void> => {
        if (updatedAccounts?.length > 0 && isAbleToSave) {
            await Promise.all(
                updatedAccounts.map(async (account) => {
                    try {
                        const resp = await communication.updateBankAccount(account, account.id);
                        if (isStatusSuccess(resp.status)) {
                            communication.getAgency().then((res: ObjectKeys) => {
                                useAgencyStore.setState({
                                    agency: res.data.data,
                                });
                            });
                            toast.success(t('pages.agency.bankAccounts.messages.edit_account_success').text);
                        } else {
                            throw Error('Error updating domestic accounts:');
                        }
                    } catch (error) {
                        if (isAxiosError(error)) {
                            if (error.response?.data.message) {
                                setErrorMessage(error.response.data.message);
                                setIsErrorModalOpen(true);
                                return;
                            }
                        }
                        toast.error(t('pages.agency.bankAccounts.messages.edit_account_error').text);
                    }
                }),
            );
        }
    };

    const handleSave = async (): Promise<void> => {
        setIsLoaderVisible(true);
        try {
            await saveNewAccount();

            const updatedAccounts = watchedExistingAccounts.filter((account) => {
                const initialAccountValue = initialData.find((oldAccount) => oldAccount.id === account.id);
                return JSON.stringify(account) !== JSON.stringify(initialAccountValue);
            });

            await saveExistingAccounts(updatedAccounts);
            await refetch();
        } catch (error) {
            console.error('Error creating or updating bank accounts:', error);
        }
        setIsLoaderVisible(false);
    };

    const handleDeleteExisting = async (bank_id: string): Promise<void> => {
        setIsLoaderVisible(true);
        try {
            const response = await communication.deleteBankAccount(bank_id);
            if (isStatusSuccess(response.status)) {
                communication.getAgency().then((res: ObjectKeys) => {
                    useAgencyStore.setState({
                        agency: res.data.data,
                    });
                });
                toast.success(t('pages.agency.bankAccounts.messages.delete_account_success').text);
                await refetch();
            }
        } catch (error) {
            setIsLoaderVisible(false);
            if (isAxiosError(error)) {
                if (error.response?.data.message) {
                    setErrorMessage(error.response.data.message);
                    setIsErrorModalOpen(true);
                    return;
                }
            }
            toast.error(t('pages.agency.bankAccounts.messages.delete_account_success').text);
        }
        setIsLoaderVisible(false);
    };

    const handleDeleteNew = (): void => {
        methods.resetField('newAccount', { keepDirty: false, defaultValue: [] });
    };

    const handleDeleteModalClose = (): void => {
        setIsDeleteModalOpen(false);
        setDeletingId(null);
    };

    const getOrderNumber = (index: number): number => {
        return (1 - 1) * itemsPerPage + index + 1;
    };

    const triggerUploadInstructionRef = (currency: string, bank_account_id: string): void => {
        setUploadingInstructionCurrency(currency);
        setUploadingInstructionBankId(bank_account_id);
        uploadInstructionRef.current?.click();
    };

    const uploadInstruction = async (file: File, bank_account_id: string, currency: string) => {
        try {
            const response = await communication.uploadForeignInstruction(
                file,
                bank_account_id,
                currency.toLowerCase(),
            );
            if (isStatusSuccess(response.status)) {
                await refetch();
                toast.success(t('pages.agency.bankAccounts.messages.paymentInstructions.add_success').text);
                return true;
            } else {
                throw new Error('Failed to upload instruction file');
            }
        } catch (error) {
            if (isAxiosError(error)) {
                if (error.response?.data.message) {
                    setErrorMessage(error.response.data.message);
                    setIsErrorModalOpen(true);
                    return;
                }
            }
            return false;
        }
    };

    const handleUploadInstruction = async () => {
        const instructionFiles = uploadInstructionRef.current?.files;

        if (instructionFiles && instructionFiles.length > 0 && uploadingInstructionBankId) {
            const instructionFile = instructionFiles[0];

            if (!INSTRUCTIONS_ALLOWED_FILE_TYPES.includes(instructionFile.type)) {
                setIsWrongFormatModalOpen(true);
                if (uploadInstructionRef.current) {
                    uploadInstructionRef.current.value = '';
                }
                return;
            }

            if (instructionFile.size > 5242880) {
                setIsOverSizeModalOpen(true);
                return;
            }

            setIsLoaderVisible(true);

            const success = await uploadInstruction(
                instructionFile,
                uploadingInstructionBankId ? uploadingInstructionBankId : '',
                uploadingInstructionCurrency ? uploadingInstructionCurrency : '',
            );

            if (success) {
                setUploadingInstructionCurrency(null);
                setUploadingInstructionBankId(null);
                if (uploadInstructionRef.current) {
                    uploadInstructionRef.current.value = '';
                }
            }
            setIsLoaderVisible(false);
        }
    };

    const handleDeleteInstruction = async (id: string) => {
        setIsLoaderVisible(true);
        try {
            const response = await communication.deleteInstruction(id);
            if (isStatusSuccess(response.status)) {
                toast.success(t('pages.agency.bankAccounts.messages.paymentInstructions.delete_success').text);
                await refetch();
            } else {
                throw new Error('Failed to delete instruction file');
            }
        } catch (error) {
            if (isAxiosError(error)) {
                if (error.response?.data.message) {
                    setErrorMessage(error.response.data.message);
                    setIsErrorModalOpen(true);
                    return;
                }
            }
            toast.error(t('pages.agency.bankAccounts.messages.paymentInstructions.delete_error').text);
        }
        setIsLoaderVisible(false);
    };

    useEffect(() => {
        if (!isFetching) {
            handleDataChange();
        }
    }, [allBankAccounts, isFetching]);

    useEffect(() => {
        uploadInstructionRef.current?.addEventListener('change', handleUploadInstruction);
        return () => {
            uploadInstructionRef.current?.removeEventListener('change', handleUploadInstruction);
        };
    }, [uploadInstructionRef.current, uploadingInstructionCurrency]);

    useEffect(() => {
        methods.trigger();
    }, [currentLang]);

    return (
        <>
            {(isLoaderVisible || isFetching) && <Loader />}
            <FormWrapper>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleSave)}>
                        {watchedExistingAccounts?.map((field, index) => {
                            return (
                                <ForeignBankAccountNumber
                                    key={field.id + index}
                                    index={index}
                                    orderNumber={getOrderNumber(index)}
                                    allBanks={allBanks || []}
                                    isAllBanksLoading={isLoading}
                                    currencyOptions={currencyOptions}
                                    disabled={!!methods.formState.dirtyFields.newAccount}
                                    paymentInstructions={
                                        field?.instructions.data?.filter(
                                            (pi: ObjectKeys) => pi.bank_account_id === field.id,
                                        ) || []
                                    }
                                    onDelete={() => {
                                        setIsDeletingAccountType('existing');
                                        setDeletingId(field.id);
                                        setIsDeleteModalOpen(true);
                                    }}
                                    onUploadInstruction={() => {
                                        setUploadingInstructionBankId(field.id);
                                        setUploadingInstructionCurrency(field.currency);
                                        triggerUploadInstructionRef(field.currency, field.id);
                                    }}
                                    onDeleteInstruction={handleDeleteInstruction}
                                />
                            );
                        })}
                        {watchedNewAccount?.map((_, index) => (
                            <ForeignBankAccountNumber
                                key={'new-account-' + index}
                                index={index}
                                isNew={true}
                                allBanks={allBanks || []}
                                isAllBanksLoading={isLoading}
                                currencyOptions={currencyOptions}
                                paymentInstructions={[]}
                                onDelete={() => {
                                    setIsDeletingAccountType('new');
                                    setIsDeleteModalOpen(true);
                                }}
                                onUploadInstruction={() => void {}}
                                onDeleteInstruction={handleDeleteInstruction}
                            />
                        ))}
                        {methods.formState.isDirty && (
                            <div className="button-container">
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={'var(--purple)'}
                                    size={200}
                                    className="big"
                                    disabled={!isAbleToSave}
                                >
                                    {t('pages.agency.bankAccounts.common.save').text}
                                </Button>
                            </div>
                        )}
                    </form>
                    {(watchedNewAccount?.length === 0 || !watchedNewAccount) &&
                        !isFetching &&
                        !methods.formState.isDirty && (
                            <AddServiceButton>
                                <div className="plus" onClick={addBankAccount}>
                                    +
                                </div>
                                <p>{t('pages.invoices.add_account').text}</p>
                            </AddServiceButton>
                        )}
                    <DevTool control={methods.control} />
                </FormProvider>
            </FormWrapper>

            <Modal modalVisible={isDeleteModalOpen} closeModal={handleDeleteModalClose}>
                <ConfirmModal
                    close={handleDeleteModalClose}
                    message={t('pages.agency.bankAccounts.messages.delete_account_confirm').text}
                    action={async () => {
                        if (isDeletingAccountType === 'existing') {
                            await handleDeleteExisting(deletingId ?? '');
                        } else {
                            handleDeleteNew();
                        }
                        handleDeleteModalClose();
                    }}
                />
            </Modal>

            <Modal modalVisible={isOverSizeModalOpen} closeModal={() => setIsOverSizeModalOpen(false)}>
                <ErrorModal t={t} errorMessage={t('pages.error.overSize').text} setOpenModal={setIsOverSizeModalOpen} />
            </Modal>

            <Modal modalVisible={isWrongFormatModalOpen} closeModal={() => setIsWrongFormatModalOpen(false)}>
                <ErrorModal
                    t={t}
                    errorMessage={t('pages.agency.paymentInstructions.wrongType').text}
                    setOpenModal={setIsWrongFormatModalOpen}
                />
            </Modal>

            <Modal modalVisible={isErrorModalOpen} closeModal={() => setIsErrorModalOpen(false)}>
                <ErrorModal t={t} errorMessage={errorMessage} setOpenModal={setIsErrorModalOpen} />
            </Modal>

            <Modal modalVisible={blocker.state === 'blocked'} closeModal={() => blocker.reset && blocker.reset()}>
                <ConfirmModal
                    message={t('warnings.youHaveUnsavedChanges').text}
                    close={() => blocker.reset && blocker.reset()}
                    action={() => blocker.proceed && blocker.proceed()}
                />
            </Modal>

            <input type="file" hidden ref={uploadInstructionRef} />
        </>
    );
};

const FormWrapper = styled.div`
    width: 100%;
    margin-top: 3rem;
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            padding-bottom: 80px;
            margin-top: 20px;
        }
    }
`;

const AddServiceButton = styled.div`
    padding: 15px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--purple);
    .plus {
        cursor: pointer;
        text-align: center;
        font-size: 25px;
        border: 2.5px solid;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        padding: 0;
        padding-top: 2.5px;
        color: var(--purple);
    }
    svg {
        height: 25px;
        width: 25px;
    }
    p {
        color: var(--purple);
        font-style: italic;
        font-size: 14px;
        margin-top: 10px;
    }
`;

export default ForeignAccounts;
