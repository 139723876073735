import { ObjectKeys } from '../../../types/objectKeys';

type SwiftIbanOption = {
    value: {
        id: string;
        bank: {
            data: {
                id: string;
            };
        };
    };
};

export const validateIbanSwift = (
    iban: string,
    swift: string,
    bank_acc_id: string,
    skip: boolean,
    agency: ObjectKeys,
    swiftIbanOptions: SwiftIbanOption[],
    data: any,
): boolean => {
    if (skip || !bank_acc_id) {
        return true;
    }

    if (!agency || swiftIbanOptions.length === 0) {
        console.log('agency does not exist');
        return false;
    }

    if (!iban || !swift) {
        console.log('iban or swift doesnt exist');
        return false;
    }

    const ibanRegex = /^[A-Z0-9]{15,34}$/;
    if (!ibanRegex.test(iban)) {
        console.log('iban doesnt match regex');
        return false;
    }

    const ibanFirst4Digits = iban.slice(0, 4);
    if (ibanFirst4Digits !== 'RS35') {
        console.log('iban doesnt start with RS35');
        return false;
    }

    const bankCode = iban.slice(4, 7);
    const bank = data?.find((banka: { code: string }) => banka.code === bankCode);
    if (!bank) {
        console.log('bank doesnt exist');
        return false;
    }

    if (swift !== bank.swift) {
        console.log('swift doesnt match bank swift');
        return false;
    }

    const matchedAccount = swiftIbanOptions.find((banka: SwiftIbanOption) => banka.value.id === bank_acc_id);

    if (!matchedAccount || !matchedAccount.value || !matchedAccount.value.bank) {
        console.log('bank account doesnt exist');
        return false;
    }

    return bank.id === matchedAccount.value.bank.data.id;
};
